import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  ADD_USER_CARD_START,
  DELETE_USER_CARD_START,
  FETCH_USER_CARD_LIST_START,
  SET_DEFAULT_USER_CARD_START,
  ADD_USER_BILLING_ACCOUNTS_START,
  FETCH_USER_BILLING_ACCOUNTS_START,
  DELETE_USER_BILLING_ACCOUNTS_START,
  SET_DEFAULT_USER_BILLING_ACCOUNTS_START,
  FETCH_USER_WALLET_DETAILS_START,
  WALLET_PAYMENT_BY_STRIPE_START,
  WITHDRAWAL_REQUEST_START,
  CANCEL_WITHDRAWAL_REQUEST_START,
  FETCH_WITHDRAWL_REQUESTS_START,
  VIEW_SINGLE_WITHDRAWAL_REQUEST_START,
  FETCH_MORE_USER_WALLET_DETAILS_START,
  FETCH_MORE_WITHDRAWAL_REQUESTS_START,
  FETCH_USER_WALLET_ADDRESS_START,
} from "../actions/ActionConstant";
import {
  addUserCardFailure,
  addUserCardSuccess,
  deleteUserCardFailure,
  deleteUserCardSuccess,
  fetchUserCardListSuccess,
  setDefaultUserCardFailure,
  setDefaultUserCardSuccess,
  addUserBillingAccountsFailure,
  addUserBillingAccountsSuccess,
  fetchUserBillingAccountsListSuccess,
  deleteUserBillingAccountsFailure,
  deleteUserBillingAccountsSuccess,
  setDefaultUserBillingAccountsFailure,
  setDefaultUserBillingAccountsSuccess,
  fetchUserWalletDetailsSuccess,
  walletPaymentByStripeFailure,
  walletPaymentByStripeSuccess,
  withdrawalRequestSuccess,
  withdrawalRequestFailure,
  cancelWithdrawalRequestSuccess,
  cancelWithdrawalRequestFailure,
  fetchWithdrawlRequestSuccess,
  fetchWithdrawlRequestsFailure,
  viewSingleWithdrawalRequestSuccess,
  viewSingleWithdrawalRequestFailure,
  fetchUserWalletAddressSuccess,
  fetchUserWalletAddressFailure,
  forceWithdrawlRequestSuccess,
} from "../actions/WalletAction";


function* addUserCardAPI(action) {
  try {
    const response = yield api.postMethod("user_cards_add", action.data);
    if (response.data.success) {
      yield put(addUserCardSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(addUserCardFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addUserCardFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchUserCardListAPI(action) {
  try {
    const response = yield api.postMethod("user_cards_list", action.data);
    if (response.data.success) {
      yield put(fetchUserCardListSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(addUserCardFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addUserCardFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deleteUserCardAPI(action) {
  try {
    const response = yield api.postMethod("user_cards_delete", action.data);
    if (response.data.success) {
      yield put(deleteUserCardSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      let cardListData = yield select((state) => state.wallet.userCardList.data);
      cardListData = {
        ...cardListData,
        user_cards: cardListData.user_cards.filter(card => card.user_card_id !== action.data.user_card_id),
      }
      yield put(fetchUserCardListSuccess(cardListData));
    } else {
      yield put(deleteUserCardFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteUserCardFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* setDefaultUserCardAPI(action) {
  try {
    const response = yield api.postMethod("user_cards_make_default", action.data);
    if (response.data.success) {
      yield put(setDefaultUserCardSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(setDefaultUserCardFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(setDefaultUserCardFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* addUserBillingAccountsAPI(action) {
  try {
    const response = yield api.postMethod("user_billing_accounts_save", action.data);
    if (response.data.success) {
      yield put(addUserBillingAccountsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(addUserBillingAccountsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addUserBillingAccountsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchUserBillingAccountsListAPI(action) {
  try {
    const response = yield api.postMethod("user_billing_accounts_list", action.data);
    if (response.data.success) {
      yield put(fetchUserBillingAccountsListSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(addUserCardFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addUserCardFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deleteUserBillingAccountsAPI(action) {
  try {
    const response = yield api.postMethod("user_billing_accounts_delete", action.data);
    if (response.data.success) {
      yield put(deleteUserBillingAccountsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      let accountListData = yield select((state) => state.wallet.userBillingAccounts.data);
      accountListData = {
        ...accountListData,
        user_billing_accounts: accountListData.user_billing_accounts.filter(account => account.user_billing_account_id !== action.data.user_billing_account_id),
      }
      yield put(fetchUserBillingAccountsListSuccess(accountListData));
    } else {
      yield put(deleteUserBillingAccountsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteUserBillingAccountsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* setDefaultUserBillingAccountsAPI(action) {
  try {
    const response = yield api.postMethod("user_billing_accounts_make_default", action.data);
    if (response.data.success) {
      yield put(setDefaultUserBillingAccountsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(setDefaultUserBillingAccountsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(setDefaultUserBillingAccountsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchUserWalletDetailsAPI(action) {
  try {
    const response = yield api.postMethod("user_wallet", action.data);
    if (response.data.success) {
      yield put(fetchUserWalletDetailsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(addUserCardFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(addUserCardFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* walletPaymentByStripeAPI(action) {
  try {
    const response = yield api.postMethod("user_wallet_payment_by_stripe", action.data);
    if (response.data.success) {
      yield put(walletPaymentByStripeSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(walletPaymentByStripeFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(walletPaymentByStripeFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* withdrawalRequestAPI(action) {
  try {
    const response = yield api.postMethod("withdrawal_requests_send", action.data);
    if (response.data.success) {
      yield put(withdrawalRequestSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(withdrawalRequestFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(withdrawalRequestFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* cancelWithdrawalRequestAPI(action) {
  try {
    const response = yield api.postMethod("withdrawal_requests_cancel", action.data);
    if (response.data.success) {
      yield put(cancelWithdrawalRequestSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      let withdrawRequestList = yield select((state) => state.wallet.withdrawRequestList.data);
      withdrawRequestList = {
        ...withdrawRequestList,
        user_wallet: response.data.data.user_wallet,
        user_withdrawals: withdrawRequestList.user_withdrawals.map((req) => req.user_withdrawal_unique_id === response.data.data.user_withdrawal.user_withdrawal_unique_id ?
          response.data.data.user_withdrawal : req
        )
      }
      yield put(forceWithdrawlRequestSuccess(withdrawRequestList));
    } else {
      yield put(cancelWithdrawalRequestFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(withdrawalRequestFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchWithdrawlRequestsAPI(action) {
  try {
    const response = yield api.postMethod("withdrawal_requests", action.data);
    if (response.data.success) {
      yield put(fetchWithdrawlRequestSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchWithdrawlRequestsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchWithdrawlRequestsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* viewSingleWithdrawlRequestAPI(action) {
  try {
    const response = yield api.postMethod("withdrawal_requests_view", action.data);
    if (response.data.success) {
      yield put(viewSingleWithdrawalRequestSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(viewSingleWithdrawalRequestFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(viewSingleWithdrawalRequestFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchUserWalletAddressAPI(action) {
  try {
    const response = yield api.postMethod("user_wallet_address", action.data);
    if (response.data.success) {
      yield put(fetchUserWalletAddressSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchUserWalletAddressFailure(response.data.error));
      // yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchUserWalletAddressFailure(error));
    // yield call(getErrorNotificationMessage, error.message);
  }
}


export default function* pageSaga() {
  yield all([
    yield takeLatest(ADD_USER_CARD_START, addUserCardAPI),
    yield takeLatest(FETCH_USER_CARD_LIST_START, fetchUserCardListAPI),
    yield takeLatest(DELETE_USER_CARD_START, deleteUserCardAPI),
    yield takeLatest(SET_DEFAULT_USER_CARD_START, setDefaultUserCardAPI),
    yield takeLatest(ADD_USER_BILLING_ACCOUNTS_START, addUserBillingAccountsAPI),
    yield takeLatest(FETCH_USER_BILLING_ACCOUNTS_START, fetchUserBillingAccountsListAPI),
    yield takeLatest(DELETE_USER_BILLING_ACCOUNTS_START, deleteUserBillingAccountsAPI),
    yield takeLatest(SET_DEFAULT_USER_BILLING_ACCOUNTS_START, setDefaultUserBillingAccountsAPI),
    yield takeLatest(FETCH_USER_WALLET_DETAILS_START, fetchUserWalletDetailsAPI),
    yield takeLatest(FETCH_MORE_USER_WALLET_DETAILS_START, fetchUserWalletDetailsAPI),
    yield takeLatest(WALLET_PAYMENT_BY_STRIPE_START, walletPaymentByStripeAPI),
    yield takeLatest(WITHDRAWAL_REQUEST_START, withdrawalRequestAPI),
    yield takeLatest(CANCEL_WITHDRAWAL_REQUEST_START, cancelWithdrawalRequestAPI),
    yield takeLatest(FETCH_WITHDRAWL_REQUESTS_START, fetchWithdrawlRequestsAPI),
    yield takeLatest(FETCH_MORE_WITHDRAWAL_REQUESTS_START, fetchWithdrawlRequestsAPI),
    yield takeLatest(VIEW_SINGLE_WITHDRAWAL_REQUEST_START, viewSingleWithdrawlRequestAPI),
    yield takeLatest(FETCH_USER_WALLET_ADDRESS_START, fetchUserWalletAddressAPI),
  ]);
}