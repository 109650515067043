import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Table,
  InputGroup,
} from "react-bootstrap";
import ProfileSideBar from "./ProfileSideBar";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { walletPaymentByStripeStart } from "../store/actions/WalletAction";
import PaymentSection from "./PaymentSection";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const WalletAddMoney = (props) => {
  const navigate = useNavigate();
  const t = useTranslation();
  const [skipFirst, setSkipFirst] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);

  const addMoney = (values) => {
    if (values.amount && selectedCard) {
      props.dispatch(
        walletPaymentByStripeStart({
          amount: values.amount,
          user_card_id: selectedCard,
        })
      );
    } else if (!values.amount) {
      props.dispatch(getErrorNotificationMessage("Please enter the amount"));
    } else if (!selectedCard) {
      props.dispatch(
        getErrorNotificationMessage("Please add or select a card")
      );
    }
  };

  const addAmountSchema = Yup.object().shape({
    amount: Yup.string().required(t("amount_required")),
  });

  useEffect(() => {
    if (
      !skipFirst &&
      !props.walletPaymentByStripe.loading &&
      Object.keys(props.walletPaymentByStripe.data).length > 0
    ) {
      navigate("/profile-wallet");
    }
    setSkipFirst(false);
  }, [props.walletPaymentByStripe]);

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title">
                  <h4>{t("add_money")}</h4>
                </div>
                <Formik
                  initialValues={{
                    amount: "",
                  }}
                  validationSchema={addAmountSchema}
                  onSubmit={addMoney}
                >
                  <FORM>
                    <div className="add-money-sec">
                      <div className="add-money-input">
                        <Form.Group className="">
                          <Field
                            type="number"
                            className="form-control"
                            name="amount"
                            placeholder={t("amount_placeholder")}
                            min="0"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="amount"
                            className="text-danger"
                          />
                        </Form.Group>
                        <div className="currency">$</div>
                      </div>
                      <div className="add-money-btn">
                        <Button
                          type="submit"
                          disabled={props.walletPaymentByStripe.buttonDisable}
                        >
                          {props.walletPaymentByStripe.loadingButtonContent
                            ? props.walletPaymentByStripe.loadingButtonContent
                            : t("add_money")}
                        </Button>
                      </div>
                    </div>
                  </FORM>
                </Formik>
                <PaymentSection
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  walletPaymentByStripe: state.wallet.walletPaymentByStripe,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(WalletAddMoney));
