import React, { useState } from 'react';

const CbdProgress = ({ percentage, width = "55px", height = "55px", fontsize = "1em" }) => {

  const [value, setValue] = useState(Math.ceil(360 * (percentage / 100)));

  const getStyle = () => {

    return {
      background: `radial-gradient(white 60%, transparent 0%),
    conic-gradient(transparent 0deg ${value}deg, gainsboro ${value}deg 360deg),
    conic-gradient(#acebff 0deg, #acebff 60deg, #3ecefc 120deg, #3ecefc 180deg, #3ecefc 240deg, #3ecefc 300deg, #3ecefc)`,
      borderRadius: "50%",
      width: width,
      height: height,
      transition: "all 500ms ease-in",
      willChange: "transform",
    }
  }

  return (
    <>
      <div className="progress-circle" style={getStyle()}>
        <div className="text" style={{ fontSize: fontsize }}>CBD</div>
        <div className="line l1" />
        <div className="line l2" />
        <div className="line l3" />
      </div>
      {/* <svg
        width="100%"
        height="100%"
        viewBox="-10 0 300 300"
      >
        <linearGradient
          id="cbdColors1-02ffc488-4b0a-4cd4-beca-5967e044b9c4"
          x1="0"
          y1="0"
          x2="1"
          y2="1"
        >
          <stop offset="0%" stop-color="#acebff"></stop>
          <stop offset="100%" stop-color="#acebff"></stop>
        </linearGradient>
        <linearGradient
          id="cbdColors2-02ffc488-4b0a-4cd4-beca-5967e044b9c4"
          x1="0.5"
          y1="0"
          x2="0.5"
          y2="1"
        >
          <stop offset="0%" stop-color="#acebff"></stop>
          <stop offset="100%" stop-color="#3ecefc"></stop>
        </linearGradient>
        <linearGradient
          id="cbdColors3-02ffc488-4b0a-4cd4-beca-5967e044b9c4"
          x1="1"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset="0%" stop-color="#3ecefc"></stop>
          <stop offset="100%" stop-color="#3ecefc"></stop>
        </linearGradient>
        <linearGradient
          id="cbdColors4-02ffc488-4b0a-4cd4-beca-5967e044b9c4"
          x1="1"
          y1="1"
          x2="0"
          y2="0"
        >
          <stop offset="0%" stop-color="#3ecefc"></stop>
          <stop offset="100%" stop-color="#3ecefc"></stop>
        </linearGradient>
        <linearGradient
          id="cbdColors5-02ffc488-4b0a-4cd4-beca-5967e044b9c4"
          x1="0.5"
          y1="1"
          x2="0.5"
          y2="0"
        >
          <stop offset="0%" stop-color="#3ecefc"></stop>
          <stop offset="100%" stop-color="#3ecefc"></stop>
        </linearGradient>
        <linearGradient
          id="cbdColors6-02ffc488-4b0a-4cd4-beca-5967e044b9c4"
          x1="0"
          y1="1"
          x2="1"
          y2="0"
        >
          <stop offset="0%" stop-color="#3ecefc"></stop>
          <stop
            offset="100%"
            stop-color="#00a99d"
            stop=""
          ></stop>
        </linearGradient>
        <text
          class="scale-text"
          x="50%"
          y="45%"
          text-anchor="middle"
          stroke="none"
          stroke-width="2px"
          dy=".3em"
        >
          CBD
        </text>{" "}
        <path
          d="M150 10 a120 120 0 0 1 103.9230 60"
          fill="none"
          stroke={value >= 1 ? "url(#cbdColors1-02ffc488-4b0a-4cd4-beca-5967e044b9c4)" : "#e1e1e1"}
          stroke-width="20"
          stroke-dasharray="120 10"
        ></path>
        <path
          d="M253.9230 70 a120 120 0 0 1 0 120"
          fill="none"
          stroke={value >= 2 ? "url(#cbdColors2-02ffc488-4b0a-4cd4-beca-5967e044b9c4)" : "#e1e1e1"}
          stroke-width="20"
          stroke-dasharray="120 10"
        ></path>
        <path
          d="M253.9230 190 a120 120 0 0 1 -103.9230 60"
          fill="none"
          stroke={value >= 3 ? "url(#cbdColors3-02ffc488-4b0a-4cd4-beca-5967e044b9c4)" : "#e1e1e1"}
          stroke-width="20"
          stroke-dasharray="120 10"
        ></path>
        <path
          d="M150 250 a120 120 0 0 1 -103.9230 -60"
          fill="none"
          stroke={value >= 4 ? "url(#cbdColors3-02ffc488-4b0a-4cd4-beca-5967e044b9c4)" : "#e1e1e1"}
          stroke-width="20"
          stroke-dasharray="120 10"
        ></path>
        <path
          d="M46.077 190 a120 120 0 0 1 0 -118"
          fill="none"
          stroke={value >= 5 ? "url(#cbdColors4-02ffc488-4b0a-4cd4-beca-5967e044b9c4)" : "#e1e1e1"}
          stroke-width="25"
          stroke-dasharray="120 10"
        ></path>
        <path
          d="M46.077 70 a120 120 0 0 1 103.9230 -60"
          fill="none"
          stroke={value >= 6 ? "url(#cbdColors5-02ffc488-4b0a-4cd4-beca-5967e044b9c4)" : "#e1e1e1"}
          stroke-width="20"
          stroke-dasharray="120 "
        ></path>
      </svg> */}
    </>
  );
}

export default CbdProgress;