import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchCategoriesListStart } from "../store/actions/HomePageAction";
import "./Landing.css";
import { connect } from "react-redux";
import Slider from "react-slick";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from 'react-router-dom';

const HeroStaticProductIndex = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(fetchCategoriesListStart());
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="hero-static-product-sec">
        <Container>
          <Row>
            <Col md={12}>
              {props.categoriesList.loading ? (
                <div className="d-flex justify-content-between">
                  <Skeleton height={260} width={240} />
                  <Skeleton height={260} width={240} />
                  <Skeleton height={260} width={240} />
                  <Skeleton height={260} width={240} />
                  <Skeleton height={260} width={240} />
                </div>
              ) : (
                <div className="">
                  <Slider {...settings}>
                    {props.categoriesList.data.categories &&
                      props.categoriesList.data.categories.map(
                        (category, i) => (
                          <div className="hero-static-product-item"
                            onClick={e => navigate(`/shop?category=${category.category_unique_id}`)}>
                            <div className="hero-static-product-card">
                              <div className="hero-static-product-img-sec">
                                {/* <Image
                                className="hero-static-product-img"
                                src={category.picture}
                              /> */}
                                <CustomLazyLoad
                                  src={category.picture}
                                  placeholderSrc={
                                    window.location.origin +
                                    "/images/loading.svg"
                                  }
                                  classes="hero-static-product-img"
                                  alt={category.name}
                                />
                              </div>
                              <div className="hero-static-product-info">
                                <h4>{category.name}</h4>
                                <p>({category.total_products})</p>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </Slider>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  categoriesList: state.homepage.categoriesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(HeroStaticProductIndex);
