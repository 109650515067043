import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Table,
  InputGroup,
  Tab,
  Nav,
} from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  addUserBillingAccountsStart,
  fetchUserBillingAccountsListStart,
  withdrawalRequestStart,
} from "../store/actions/WalletAction";
import ProfileSideBar from "./ProfileSideBar";
import { Link } from "react-router-dom";
import SingleBillingAddressCard from "./SingleBillingAddressCard";
import SomethingWrong from "../Helper/SomethingWrong";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";
import { useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import NoBillingAddressFound from "../Helper/NoBillingAddressFound";

const WalletWithdrawMoney = (props) => {
  const [activeTab, setActiveTab] = useState("saved_billing");
  const [skipRender, setSkipRender] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const navigate = useNavigate();
  const t = useTranslation();

  useEffect(() => {
    if (
      !skipRender &&
      !props.addUserBillingAccounts.loading &&
      Object.keys(props.addUserBillingAccounts.data).length > 0
    ) {
      setActiveTab("saved_billing");
      props.dispatch(fetchUserBillingAccountsListStart());
    }
  }, [props.addUserBillingAccounts]);

  const handleSubmit = (values) => {
    props.dispatch(addUserBillingAccountsStart(values));
  };

  const addAmount = (values) => {
    if (values.amount && selectedAccount) {
      props.dispatch(
        withdrawalRequestStart({
          amount: values.amount,
          user_billing_account_id: selectedAccount,
        })
      );
    } else if (!values.amount) {
      props.dispatch(getErrorNotificationMessage(t("please_enter_the_amount")));
    } else if (!selectedAccount) {
      props.dispatch(
        getErrorNotificationMessage(t("please_add_or_select_an_account"))
      );
    }
  };

  useEffect(() => {
    props.dispatch(fetchUserBillingAccountsListStart());
  }, []);

  const addbillingSchema = Yup.object().shape({
    route_number: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[0-9])(?=.{7,})/, t("must_contain_7_characters")),
    account_number: Yup.string()
      .required(t("account_number_required"))
      .min(11, t("min_11"))
      .max(20, t("min_20")),
    first_name: Yup.string().required(t("required")),
    last_name: Yup.string().required(t("required")),
  });

  const addAmountSchema = Yup.object().shape({
    amount: Yup.string().required(t("amount_required")),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !props.userBillingAccounts.loading &&
      Object.keys(props.userBillingAccounts.data).length > 0
    ) {
      if (
        props.userBillingAccounts.data.user_billing_accounts &&
        props.userBillingAccounts.data.user_billing_accounts.length > 0
      ) {
        props.userBillingAccounts.data.user_billing_accounts.map(
          (account, i) => {
            if (account.is_default === 1)
              setSelectedAccount(account.user_billing_account_id);
          }
        );
      } else {
        setSelectedAccount(null);
      }
    }
  }, [props.userBillingAccounts]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.withdrawRequest.loading &&
      Object.keys(props.withdrawRequest.data).length > 0
    ) {
      navigate("/withdraw");
    }
    setSkipRender(false);
  }, [props.withdrawRequest]);

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title">
                  <h4>{t("withdraw_money")}</h4>
                </div>
                <Formik
                  initialValues={{
                    amount: "",
                  }}
                  validationSchema={addAmountSchema}
                  onSubmit={addAmount}
                >
                  <FORM>
                    <div className="add-money-sec">
                      <div className="add-money-input">
                        <Form.Group className="">
                          <Field
                            type="number"
                            className="form-control"
                            name="amount"
                            placeholder={t("amount_placeholder")}
                            min="0"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="amount"
                            className="text-danger"
                          />
                        </Form.Group>
                        <div className="currency">$</div>
                      </div>
                      <div className="add-money-btn">
                        <Button type="submit">
                          {props.withdrawRequest.loadingButtonContent
                            ? props.withdrawRequest.loadingButtonContent
                            : t("withdraw")}
                        </Button>
                      </div>
                    </div>
                    <div className="account-balance mt-4">
                      <h4>
                        <span>{t("balance")}</span>
                        {props.userBillingAccounts.loading ? (
                          <p>{t("loading")}</p>
                        ) : Object.keys(props.userBillingAccounts.data).length >
                            0 && props.userBillingAccounts.data.user_wallet ? (
                          props.userBillingAccounts.data.user_wallet
                            .remaining_formatted
                        ) : (
                          "0" (t("usd"))
                        )}
                      </h4>
                    </div>
                  </FORM>
                </Formik>
                <div className="address-tab mt-5">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      <Col md={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => setActiveTab("saved_billing")}
                              eventKey="first"
                            >
                              {t("saved_billing")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              onClick={() => setActiveTab("new_billing")}
                              eventKey="second"
                            >
                              {t("new_billing")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col md={12}>
                        <Tab.Content>
                          {activeTab === "saved_billing" && (
                            <div>
                              <div className="wallet-withdraw-money-sec">
                                {props.userBillingAccounts.loading ? (
                                  <Skeleton count={2} height={100} />
                                ) : props.userBillingAccounts.data
                                    .user_billing_accounts ? (
                                  props.userBillingAccounts.data
                                    .user_billing_accounts.length > 0 ? (
                                    props.userBillingAccounts.data.user_billing_accounts.map(
                                      (account, i) => (
                                        <SingleBillingAddressCard
                                          account={account}
                                          selectedAccount={selectedAccount}
                                          setSelectedAccount={
                                            setSelectedAccount
                                          }
                                        />
                                      )
                                    )
                                  ) : (
                                    <NoBillingAddressFound />
                                  )
                                ) : (
                                  <SomethingWrong />
                                )}
                              </div>
                            </div>
                          )}
                          {activeTab === "new_billing" && (
                            <div>
                              <div className="new-address-sec mt-4">
                                <div className="login-form">
                                  <Formik
                                    initialValues={{
                                      route_number: "",
                                      account_number: "",
                                      first_name: "",
                                      last_name: "",
                                      bank_type: "savings",
                                      business_name: "",
                                    }}
                                    validationSchema={addbillingSchema}
                                    onSubmit={(values) => handleSubmit(values)}
                                  >
                                    <FORM>
                                      <Row>
                                        <Col md={6}>
                                          <Form.Group
                                            className="mb-4"
                                            controlId="exampleForm.ControlInput1"
                                          >
                                            <Form.Label>
                                              {t("route_number")}*
                                            </Form.Label>
                                            <Field
                                              type="number"
                                              min="0"
                                              className="form-control"
                                              name="route_number"
                                              placeholder={t(
                                                "route_number_placeholder"
                                              )}
                                            />
                                            <ErrorMessage
                                              component={"div"}
                                              name="route_number"
                                              className="text-danger"
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                          <Form.Group
                                            className="mb-4"
                                            controlId="exampleForm.ControlInput1"
                                          >
                                            <Form.Label>
                                              {t("account_number")}*
                                            </Form.Label>
                                            <Field
                                              type="number"
                                              className="form-control"
                                              name="account_number"
                                              placeholder={t(
                                                "account_number_placeholder"
                                              )}
                                              min="0"
                                            />
                                            <ErrorMessage
                                              component={"div"}
                                              name="account_number"
                                              className="text-danger"
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                          <Form.Group
                                            className="mb-4"
                                            controlId="exampleForm.ControlInput1"
                                          >
                                            <Form.Label>
                                              {t("first_name")}
                                            </Form.Label>
                                            <Field
                                              type="text"
                                              className="form-control"
                                              name="first_name"
                                              placeholder={t(
                                                "first_name_placeholder"
                                              )}
                                            />
                                            <ErrorMessage
                                              component={"div"}
                                              name="first_name"
                                              className="text-danger"
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                          <Form.Group
                                            className="mb-4"
                                            controlId="exampleForm.ControlInput1"
                                          >
                                            <Form.Label>
                                              {t("last_name")}
                                            </Form.Label>
                                            <Field
                                              type="text"
                                              className="form-control"
                                              name="last_name"
                                              placeholder={t(
                                                "last_name_placeholder"
                                              )}
                                            />
                                            <ErrorMessage
                                              component={"div"}
                                              name="last_name"
                                              className="text-danger"
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                          <Form.Group
                                            className="mb-4 select-bank-type"
                                            controlId="exampleForm.ControlInput1"
                                          >
                                            <Form.Label>
                                              {t("bank_type")}
                                            </Form.Label>
                                            <Field
                                              as="select"
                                              className="form-control"
                                              name="bank_type"
                                              placeholder={t(
                                                "bank_type_placeholder"
                                              )}
                                            >
                                              <option value="savings">
                                                {t("savings")}
                                              </option>
                                              <option value="checking">
                                                {t("checking")}
                                              </option>
                                            </Field>
                                            <ErrorMessage
                                              component={"div"}
                                              name="bank_type"
                                              className="text-danger"
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                          <Form.Group
                                            className="mb-4"
                                            controlId="exampleForm.ControlInput1"
                                          >
                                            <Form.Label>
                                              {t("business_name")}
                                            </Form.Label>
                                            <Field
                                              type="text"
                                              className="form-control"
                                              name="business_name"
                                              placeholder={t(
                                                "business_name_placeholder"
                                              )}
                                            />
                                            <ErrorMessage
                                              component={"div"}
                                              name="business_name"
                                              className="text-danger"
                                            />
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                      <div className="mt-4">
                                        <Button
                                          type="submit"
                                          className="subscribe-btn"
                                          disabled={
                                            props.addUserBillingAccounts
                                              .buttonDisable
                                          }
                                        >
                                          {props.addUserBillingAccounts
                                            .loadingButtonContent
                                            ? props.addUserBillingAccounts
                                                .loadingButtonContent
                                            : t("save")}
                                        </Button>
                                      </div>
                                    </FORM>
                                  </Formik>
                                </div>
                              </div>
                            </div>
                          )}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  addUserBillingAccounts: state.wallet.addUserBillingAccounts,
  userBillingAccounts: state.wallet.userBillingAccounts,
  defaultUserBillingAccounts: state.wallet.defaultUserBillingAccounts,
  deleteUserBillingAccounts: state.wallet.deleteUserBillingAccounts,
  withdrawRequest: state.wallet.withdrawRequest,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(WalletWithdrawMoney));
