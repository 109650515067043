import {
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_START,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REGENERATE_EMAIL_VERIFICATION_CODE_START,
  REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS,
  REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  TWO_STEP_AUTH_LOGIN_START,
  TWO_STEP_AUTH_LOGIN_SUCCESS,
  TWO_STEP_AUTH_LOGIN_FAILURE,
  TWO_STEP_AUTH_RESEND_CODE_START,
  TWO_STEP_AUTH_RESEND_CODE_SUCCESS,
  TWO_STEP_AUTH_RESEND_CODE_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  TWO_STEP_AUTH_UPDATE_START,
  TWO_STEP_AUTH_UPDATE_SUCCESS,
  TWO_STEP_AUTH_UPDATE_FAILURE,
  FETCH_DASHBOARD_START,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  USERNAME_VALIDATION_START,
  USERNAME_VALIDATION_SUCCESS,
  USERNAME_VALIDATION_FAILURE,
  FETCH_SALES_REPORT_START,
  FETCH_SALES_REPORT_SUCCESS,
  FETCH_SALES_REPORT_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  profile: {
    data: {},
    loading: true,
    error: false,
  },
  profileInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    status: null,
  },
  loginInputData: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  register: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    status: null,
  },
  forgotPassword: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    status: null,
  },
  logout: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
    status: null,
  },
  regenerateEmailVerificationCode: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  verifyEmail: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  twoStepAuthLogin: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  twoStepAuthResedCode: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  changePassword: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  deleteAccount: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  twoStepAuthUpdate: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  dashboard: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  resetPassword: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  usernameValidation: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  salesReport: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS_START:
      return {
        ...state,
        profile: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        profile: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case UPDATE_USER_DETAILS_START:
      return {
        ...state,
        profileInputData: {
          ...state.profileInputData,
          data: {
            name: state.profile.data.name,
            picture: action.data
              ? action.data.picture != undefined
                ? action.data.picture
                : ""
              : "",
          },
          error: false,
          loading: true,
          buttonDisable: true,
          loadingButtonContent: "Loading...please wait",
        },
      };

    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        profileInputData: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        profileInputData: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
          status: true,
        },
      };
    case LOGIN_START:
      return {
        ...state,
        loginInputData: {
          data: {
            ...action.data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          loading: true,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.data,
          loading: false,
          error: false,
        },
        loginInputData: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginInputData: {
          data: {},
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case REGISTER_START:
      return {
        ...state,
        register: {
          ...state.register,
          data: {
            ...action.data,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          loading: true,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        register: {
          ...state.register,
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        register: {
          ...state.register,
          data: {},
          loading: false,
          error: true,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FORGOT_PASSWORD_START:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          data: action.data,
          loading: true,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          data: action,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          data: {},
          loading: false,
          error: true,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case LOGOUT_START:
      return {
        ...state,
        logout: {
          ...state.logout,
          data: action.data,
          loading: true,
          buttonDisable: true,
          loadingButtonContent: "Loading please wait",
        },
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        logout: {
          ...state.logout,
          data: action,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        logout: {
          ...state.logout,
          data: {},
          loading: false,
          error: true,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case REGENERATE_EMAIL_VERIFICATION_CODE_START:
      return {
        ...state,
        regenerateEmailVerificationCode: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case REGENERATE_EMAIL_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        regenerateEmailVerificationCode: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case REGENERATE_EMAIL_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        regenerateEmailVerificationCode: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case VERIFY_EMAIL_START:
      return {
        ...state,
        verifyEmail: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmail: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        verifyEmail: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_LOGIN_START:
      return {
        ...state,
        twoStepAuthLogin: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case TWO_STEP_AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        twoStepAuthLogin: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_LOGIN_FAILURE:
      return {
        ...state,
        twoStepAuthLogin: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_RESEND_CODE_START:
      return {
        ...state,
        twoStepAuthResedCode: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case TWO_STEP_AUTH_RESEND_CODE_SUCCESS:
      return {
        ...state,
        twoStepAuthResedCode: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_RESEND_CODE_FAILURE:
      return {
        ...state,
        twoStepAuthResedCode: {
          data: {},
          loading: false,
          error: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePassword: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case DELETE_ACCOUNT_START:
      return {
        ...state,
        deleteAccount: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccount: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccount: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_UPDATE_START:
      return {
        ...state,
        twoStepAuthUpdate: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case TWO_STEP_AUTH_UPDATE_SUCCESS:
      return {
        ...state,
        twoStepAuthUpdate: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case TWO_STEP_AUTH_UPDATE_FAILURE:
      return {
        ...state,
        twoStepAuthUpdate: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_DASHBOARD_START:
      return {
        ...state,
        dashboard: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboard: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case RESET_PASSWORD_START:
      return {
        ...state,
        resetPassword: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case USERNAME_VALIDATION_START:
      return {
        ...state,
        usernameValidation: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case USERNAME_VALIDATION_SUCCESS:
      return {
        ...state,
        usernameValidation: {
          data: action.data,
          loading: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case USERNAME_VALIDATION_FAILURE:
      return {
        ...state,
        usernameValidation: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_SALES_REPORT_START:
      return {
        ...state,
        salesReport: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_SALES_REPORT_SUCCESS:
      return {
        ...state,
        salesReport: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_SALES_REPORT_FAILURE:
      return {
        ...state,
        salesReport: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    default:
      return state;
  }
};

export default userReducer;
