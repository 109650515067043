import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Table,
} from "react-bootstrap";
import ProfileSideBar from "./ProfileSideBar";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  fetchMoreWithdrawlRequestStart,
  fetchWithdrawlRequestsStart,
  fetchUserWalletDetailsStart,
  cancelWithdrawalRequestStart,
} from "../store/actions/WalletAction";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";
import { connect } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import NoDataFound from "../Helper/NoDataFound";

const Withdraw = (props) => {
  const navigate = useNavigate();
  const [skipFirst, setSkipFirst] = useState(true);
  const t = useTranslation();

  useEffect(() => {
    props.dispatch(
      fetchWithdrawlRequestsStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);


  const fetchMoreWithdraw = () => {
    props.dispatch(
      fetchMoreWithdrawlRequestStart({
        skip: props.withdrawRequestList.data.user_withdrawals.length,
        take: 12,
      })
    );
  };

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage(t("transaction_id_copied")));
  };

  const cancelWithdraw = (user_withdrawal_unique_id) => {
    props.dispatch(
      cancelWithdrawalRequestStart({
        user_withdrawal_unique_id: user_withdrawal_unique_id,
      })
    );
  };

  useEffect(() => {}, [cancelWithdraw]);

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title">
                  <h4>{t("withdraw")}</h4>
                </div>
                { props.withdrawRequestList.loading ?
                <Skeleton
                height={100}
                />
                :
                <div className="wallet-box">
                  <div className="wallet-amount">
                    <h4>
                      {
                      props.userWalletDetails.data.user_wallet
                        ? props.userWalletDetails.data.user_wallet
                            .remaining_formatted
                        : "0.00 USD"}
                    </h4>
                    <p>{t("current_wallet_balance")}</p>
                  </div>
                  <div className="wallet-btn">
                    <Link to="/wallet-withdraw-money" className="black-btn">
                      <span>
                        <Image
                          src={
                            window.location.origin + "/images/withdrawal.png"
                          }
                          type="image/png"
                        />
                      </span>
                      {t("withdraw")}
                    </Link>
                  </div>
                </div>
                }
                <div className="wallet-table-sec mt-4">
                  <h5>{t("all_transaction_details")}</h5>
                  {props.withdrawRequestList.loading ? (
                    <CommonCenterLoader />
                  ) : (
                    <InfiniteScroll
                      dataLength={
                        props.withdrawRequestList.data.user_withdrawals.length
                      }
                      next={fetchMoreWithdraw}
                      hasMore={
                        props.withdrawRequestList.data.user_withdrawals.length <
                        props.withdrawRequestList.data.total
                      }
                      loader={<CommonCenterLoader />}
                    >{
                      props.withdrawRequestList.data.user_withdrawals &&
                      props.withdrawRequestList.data.user_withdrawals
                        .length > 0
                        ? 
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>{t("date")}</th>
                            <th>{t("transaction_id")}</th>
                            <th>{t("account")}</th>
                            <th>{t("withdrawal")}</th>
                            <th>{t("status")}</th>
                            <th>{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.withdrawRequestList.data.user_withdrawals.map(
                                (withdraw, i) => (
                                  <tr key={i}>
                                    <td>{withdraw.created_formatted}</td>
                                    <td>
                                      {withdraw.payment_id ? (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip>
                                              {withdraw.payment_id}
                                            </Tooltip>
                                          }
                                        >
                                          <div
                                            className="transaction-id-sec"
                                            title={withdraw.payment_id}
                                          >
                                            <CopyToClipboard
                                              text={withdraw.payment_id}
                                              onCopy={() => onCopy()}
                                            >
                                              <span>{withdraw.payment_id}</span>
                                            </CopyToClipboard>
                                          </div>
                                        </OverlayTrigger>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {withdraw.user_billing_account_name}
                                    </td>
                                    <td>
                                      {withdraw.requested_amount_formatted}
                                    </td>
                                    <td>{withdraw.status_formatted}</td>
                                    <td className="transaction-success">
                                      {withdraw.status === 0 ? (
                                        <Button
                                          variant="danger"
                                          onClick={(e) =>
                                            cancelWithdraw(
                                              withdraw.user_withdrawal_unique_id
                                            )
                                          }
                                          disabled={
                                            props.cancelWithdrawRequest
                                              .buttonDisable &&
                                            props.cancelWithdrawRequest.data
                                              .user_withdrawal_unique_id ===
                                              withdraw.user_withdrawal_unique_id
                                          }
                                        >
                                          {props.cancelWithdrawRequest.data
                                            .user_withdrawal_unique_id ===
                                            withdraw.user_withdrawal_unique_id &&
                                          props.cancelWithdrawRequest
                                            .loadingButtonContent
                                            ? props.cancelWithdrawRequest
                                                .loadingButtonContent
                                            : t("cancel")}
                                        </Button>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                  </tr>
                                )
                              )
                            }
                        </tbody>
                      </Table>
                            : 
                            <NoDataFound/>
                        }
                    </InfiniteScroll>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  withdrawRequestList: state.wallet.withdrawRequestList,
  cancelWithdrawRequest: state.wallet.cancelWithdrawRequest,
  userWalletDetails: state.wallet.userWalletDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(Withdraw));
