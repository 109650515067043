import React, { useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductCard from "../Product/ProductCard";
import { withTranslation, useTranslation } from "react-multi-lang";
import "./Landing.css";
import configuration from "react-global-configuration";

const NewArrivalIndex = (props) => {

  const t = useTranslation();


  return (
    <>
      <div className="new-arrival-sec">
        <div className="new-arrival-box">
          <div className="new-arrival-card-left">
            <div className="new-arrival-card-left-img-sec">
              <Image
                className="new-arrival-card-left-img"
                src={
                  window.location.origin +
                  "/images/new-arrivals/arrival-img.png"
                }
                type="image/png"
                alt={`${configuration.get("configData.site_name")} Ecommerce Platform`}
              />
            </div>
          </div>
          <div className="new-arrival-card-right">
            <div className="section-title-sec">
              <h3 className="title-light">{t("new_arrivals")}</h3>
              <h1 className="title-bold">
                <span>{t("new_arrivals_text",{site_name: `${configuration.get("configData.site_name")}`})}</span>
              </h1>
            </div>
            <div className="new-arrival-product-box">
              {props.products
                .slice(Math.random(0, props.products.length - 1), 2)
                .map((product, i) => (
                  <ProductCard product={product} key={i} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default (withTranslation(NewArrivalIndex));