import React from "react";

const PageNotFound = () => {
  return (
    <>
      <div className="not-found-page">
        <img src={window.location.origin + "/images/404-new.png"} />
      </div>
    </>
  );
};

export default PageNotFound;
