import React, { useState } from 'react';
import { Form, Row, Col, InputGroup, Image, Button } from 'react-bootstrap';
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import configuration from "react-global-configuration";
import { getErrorNotificationMessage } from '../Helper/ToastNotification';
import { addUserCardStart } from '../store/actions/WalletAction';
import { connect } from 'react-redux';

const CardSection = (props) => {
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardComplete, setCardComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const submitCard = async () => {
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });


    if (payload.error) {
      props.dispatch(getErrorNotificationMessage(payload.error))
    } else {
      console.log(payload);
      props.dispatch(addUserCardStart({
        card_token: payload.paymentMethod.id,
        card_holder_name: cardHolderName,
      }));
    }
  }

  return (
    <Form >
      <div className="payment-card-box">
        <Row>
          <Col md={6}>
            <Form.Label>Card Number*</Form.Label>
            <InputGroup className="mb-4">
              <CardNumberElement options={{ showIcon: true }} className="form-control" onChange={e => {
                // setError(e.error);
                setCardComplete(e.complete);
              }} />
            </InputGroup>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-4">
              <Form.Label>Card Holder*</Form.Label>
              <Form.Control
                type="text"
                placeholder="John mathew"
                value={cardHolderName}
                onChange={e => setCardHolderName(e.target.value)} />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-4">
              <Form.Label>Expiry Date*</Form.Label>
              {/* <Form.Control type="text" placeholder="16/26" /> */}
              <CardExpiryElement className="form-control" onChange={e => {
                // setError(e.error);
                setCardComplete(e.complete);
              }} />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-4">
              <Form.Label>Cvv*</Form.Label>
              {/* <Form.Control type="text" placeholder="***" /> */}
              <CardCvcElement className="form-control" onChange={e => {
                // setError(e.error);
                setCardComplete(e.complete);
              }} />
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="payment-card-btn default-btn-sec">
        <Button
          onClick={e => submitCard()}
          className="default-btn"
          disabled={!cardComplete || props.addUserCard.buttonDisable}
        >
          {props.addUserCard.loadingButtonContent ?
            props.addUserCard.loadingButtonContent
            : "save"
          }
        </Button>
      </div>
    </Form>
  );
}

const AddCardSection = (props) => {
  const stripePromise = loadStripe(configuration.get("configData.stripe_publishable_key"));

  return (
    <div className="new-add-card-sec">
      <div className="login-form">
        <Elements stripe={stripePromise}>
          <CardSection dispatch={props.dispatch} addUserCard={props.addUserCard} />
        </Elements>
      </div>
    </div>
  );
}

const mapStateToPros = (state) => ({
  addUserCard: state.wallet.addUserCard,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(AddCardSection);