import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  SAVE_REVIEWS_START,
  SAVE_REVIEWS_FILES_START,
  DELETE_REVIEWS_FILES_START,
  FETCH_REVIEWS_LIST_START,
  DELETE_REVIEWS_START,
} from "../actions/ActionConstant";
import { fetchSingleProductSuccess, forceFetchProductReviewListSuccess } from "../actions/ProductAction";

import {
  saveReviewsSuccess,
  saveReviewsFailure,
  saveReviewsFilesSuccess,
  saveReviewsFilesFailure,
  deleteReviewsFilesSuccess,
  deleteReviewsFilesFailure,
  fetchReviewsListSuccess,
  fetchReviewsListFailure,
  deleteReviewsSuccess,
  deleteReviewsFailure,
  fetchReviewsListStart,
} from "../actions/ReviewsAction";

function* saveReviewsAPI(action) {
  try {
    const response = yield api.postMethod("reviews_save", action.data);

    if (response.data.success) {
      yield put(saveReviewsSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.message);

      const productReviewsData = yield select((state) => state.product.productReviews.data);
      if (Object.keys(productReviewsData).length > 0) {
        const newData = {
          ...productReviewsData,
          reviews: [response.data.data.review, ...productReviewsData.reviews],
          total_reviews: productReviewsData.total_reviews + 1,
        };
        console.log(newData);
        yield put(forceFetchProductReviewListSuccess(newData));
      }
      const singleProductData = yield select((state) => state.product.singleProduct.data);
      if (Object.keys(singleProductData).length > 0) {
        yield put(fetchSingleProductSuccess({
          ...singleProductData,
          product: {
            ...singleProductData.product,
            review_stars: response.data.data.review_stars,
          }
        }));
      }
    } else {
      yield put(saveReviewsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(saveReviewsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }

}

function* saveReviewsFilesAPI(action) {
  try {
    const response = yield api.postMethod("review_files_save", action.data);

    if (response.data.success) {
      yield put(saveReviewsFilesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.message);
    } else {
      yield put(saveReviewsFilesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(saveReviewsFilesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }

}

function* deleteReviewsFilesAPI(action) {
  try {
    const response = yield api.postMethod("review_files_delete", action.data);

    if (response.data.success) {
      yield put(deleteReviewsFilesSuccess(response.data));
      yield put(fetchReviewsListStart());
    } else {
      yield put(deleteReviewsFilesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteReviewsFilesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchReviewsListAPI() {
  try {
    const response = yield api.postMethod("reviews_list");

    if (response.data.success) {
      yield put(fetchReviewsListSuccess(response.data.data));
    } else {
      yield put(fetchReviewsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchReviewsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deleteReviewsAPI(action) {
  try {
    const response = yield api.postMethod("reviews_delete", action.data);

    if (response.data.success) {
      yield put(deleteReviewsSuccess(response.data.data));
      yield put(fetchReviewsListStart());
    } else {
      yield put(deleteReviewsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteReviewsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(SAVE_REVIEWS_START, saveReviewsAPI),
    yield takeLatest(SAVE_REVIEWS_FILES_START, saveReviewsFilesAPI),
    yield takeLatest(DELETE_REVIEWS_FILES_START, deleteReviewsFilesAPI),
    yield takeLatest(FETCH_REVIEWS_LIST_START, fetchReviewsListAPI),
    yield takeLatest(DELETE_REVIEWS_START, deleteReviewsAPI),
  ]);
}