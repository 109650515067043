import React, { useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Table,
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import ProfileSideBar from "./ProfileSideBar";
import { Link } from "react-router-dom";
import {
  fetchMoreUserWalletDetailsStart,
  fetchUserWalletDetailsStart,
} from "../store/actions/WalletAction";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";
import InfiniteScroll from "react-infinite-scroll-component";
import CopyToClipboard from "react-copy-to-clipboard";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";

const ProfileWallet = (props) => {
  const t = useTranslation();

  useEffect(() => {
    props.dispatch(
      fetchUserWalletDetailsStart({
        skip: 0,
        take: 12,
      })
    );
  }, []);

  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage(t("transaction_id_copied")));
  };

  const fetchMoreWalletPayments = () => {
    props.dispatch(
      fetchMoreUserWalletDetailsStart({
        skip: props.userWalletDetails.data.user_wallet_payments.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title">
                  <h4>{t("wallet")}</h4>
                </div>
                { props.userWalletDetails.loading ?
                <Skeleton
                height={100} 
                />
                :
                (<div className="wallet-box">
                  <div className="wallet-amount">
                    <h4>
                      {!props.userWalletDetails.loading &&
                      Object.keys(props.userWalletDetails.data).length > 0
                        ? props.userWalletDetails.data.user_wallet
                            .remaining_formatted
                        : "0.00 USD"}
                    </h4>
                    <p>{t("current_wallet_balance")}</p>
                  </div>
                  <div className="wallet-btn">
                    <Link to="/wallet-add-money" className="wishlist-btn">
                      <span>
                        <Image
                          src={window.location.origin + "/images/wallet-1.svg"}
                          type="image/svg"
                          className="wallet-icon"
                        />
                      </span>
                      {t("add_money")}
                    </Link>
                  </div>
                </div>)
                }
                <div className="wallet-table-sec mt-4">
                  <h5>{t("all_transactions")}</h5>
                  {props.userWalletDetails.loading ? (
                    <CommonCenterLoader />
                  ) : (
                    <InfiniteScroll
                      dataLength={
                        props.userWalletDetails.data.user_wallet_payments.length
                      }
                      next={fetchMoreWalletPayments}
                      hasMore={
                        props.userWalletDetails.data.user_wallet_payments
                          .length < props.userWalletDetails.data.total
                      }
                      loader={<CommonCenterLoader />}
                    >
                      {props.userWalletDetails.data.user_wallet_payments &&
                      props.userWalletDetails.data.user_wallet_payments.length >
                        0 ? (
                        <Table striped bordered hover responsive>
                          <thead>
                            <tr>
                              <th>{t("date")}</th>
                              <th>{t("transaction_id")}</th>
                              <th>{t("amount")}</th>
                              <th>{t("status")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.userWalletDetails.data.user_wallet_payments.map(
                              (payment, i) => (
                                <tr key={i}>
                                  <td>{payment.paid_date_formatted}</td>
                                  <td style={{ cursor: "pointer" }}>
                                    {payment.payment_id ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            {payment.payment_id}
                                          </Tooltip>
                                        }
                                      >
                                        <div className="transaction-id-sec">
                                          <CopyToClipboard
                                            text={payment.payment_id}
                                            onCopy={() => onCopy()}
                                          >
                                            <span>{payment.payment_id}</span>
                                          </CopyToClipboard>
                                        </div>
                                      </OverlayTrigger>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>
                                    {payment.amount_type === "add"
                                      ? "+ "
                                      : "- "}
                                    {payment.requested_amount_formatted}
                                  </td>
                                  <td className="text-success">
                                    {payment.message}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <NoDataFound />
                      )}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  userWalletDetails: state.wallet.userWalletDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ProfileWallet));
