import {
  SAVE_ORDERS_DIRECT_START,
  SAVE_ORDERS_DIRECT_SUCCESS,
  SAVE_ORDERS_DIRECT_FAILURE,
  SAVE_ORDERS_CART_START,
  SAVE_ORDERS_CART_SUCCESS,
  SAVE_ORDERS_CART_FAILURE,
  FETCH_ORDERS_LIST_START,
  FETCH_MORE_ORDERS_LIST_START,
  FETCH_ORDERS_LIST_SUCCESS,
  FETCH_ORDERS_LIST_FAILURE,
  FETCH_ORDERS_DETAILS_START,
  FETCH_ORDERS_DETAILS_SUCCESS,
  FETCH_ORDERS_DETAILS_FAILURE,
  CANCEL_ORDERS_START,
  CANCEL_ORDERS_SUCCESS,
  CANCEL_ORDERS_FAILURE,
  INVOICE_ORDERS_START,
  INVOICE_ORDERS_SUCCESS,
  INVOICE_ORDERS_FAILURE,
  TRANSACTION_LIST_START,
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_FAILURE,
  MORE_TRANSACTION_LIST_START,
  ORDERS_SEND_CANCEL_REQUEST_START,
  ORDERS_SEND_CANCEL_REQUEST_SUCCESS,
  ORDERS_SEND_CANCEL_REQUEST_FAILURE,
  ORDERS_SEND_RETURN_REQUEST_START,
  ORDERS_SEND_RETURN_REQUEST_SUCCESS,
  ORDERS_SEND_RETURN_REQUEST_FAILURE,
  ORDERS_CHECKOUT_START,
  ORDERS_CHECKOUT_FAILURE,
  ORDERS_CHECKOUT_SUCCESS
} from "./ActionConstant";

//order save direct checkout

export function saveOrdersDirectStart(data) {
  return {
    type: SAVE_ORDERS_DIRECT_START,
    data,
  };
}

export function saveOrdersDirectSuccess(data) {
  return {
    type: SAVE_ORDERS_DIRECT_SUCCESS,
    data,
  };
}

export function saveOrdersDirectFailure(error) {
  return {
    type: SAVE_ORDERS_DIRECT_FAILURE,
    error,
  };
}

// save order cart checkout

export function saveOrdersCartStart(data) {
  return {
    type: SAVE_ORDERS_CART_START,
    data,
  };
}

export function saveOrdersCartSuccess(data) {
  return {
    type: SAVE_ORDERS_CART_SUCCESS,
    data,
  };
}

export function saveOrdersCartFailure(error) {
  return {
    type: SAVE_ORDERS_CART_FAILURE,
    error,
  };
}

// orders list

export function fetchOrdersListStart(data) {
  return {
    type: FETCH_ORDERS_LIST_START,
    data,
  };
}

export function fetchMoreOrdersListStart(data) {
  return {
    type: FETCH_MORE_ORDERS_LIST_START,
    data,
  }
}

export function fetchOrdersListSuccess(data) {
  return {
    type: FETCH_ORDERS_LIST_SUCCESS,
    data,
  };
}

export function fetchOrdersListFailure(error) {
  return {
    type: FETCH_ORDERS_LIST_FAILURE,
    error,
  };
}

// orders view

export function fetchOrdersDetailsStart(data) {
  return {
    type: FETCH_ORDERS_DETAILS_START,
    data,
  };
}

export function fetchOrdersDetailsSuccess(data) {
  return {
    type: FETCH_ORDERS_DETAILS_SUCCESS,
    data,
  };
}

export function fetchOrdersDetailsFailure(error) {
  return {
    type: FETCH_ORDERS_DETAILS_FAILURE,
    error,
  };
}

export function cancelOrdersStart(data) {
  return {
    type: CANCEL_ORDERS_START,
    data,
  };
}

export function cancelOrdersSuccess(data) {
  return {
    type: CANCEL_ORDERS_SUCCESS,
    data,
  };
}

export function cancelOrdersFailure(error) {
  return {
    type: CANCEL_ORDERS_FAILURE,
    error,
  };
}

export function invoiceOrdersStart(data) {
  return {
    type: INVOICE_ORDERS_START,
    data,
  };
}

export function invoiceOrdersSuccess(data) {
  return {
    type: INVOICE_ORDERS_SUCCESS,
    data,
  };
}

export function invoiceOrdersFailure(error) {
  return {
    type: INVOICE_ORDERS_FAILURE,
    error,
  };
}

export function transactionListStart(data) {
  return {
    type: TRANSACTION_LIST_START,
    data,
  };
}

export function transactionListSuccess(data) {
  return {
    type: TRANSACTION_LIST_SUCCESS,
    data,
  };
}

export function transactionListFailure(error) {
  return {
    type: TRANSACTION_LIST_FAILURE,
    error,
  };
}

export function moreTransactionListStart(data) {
  return {
    type: MORE_TRANSACTION_LIST_START,
    data,
  }
}

//Order Cancel
export function ordersSendCancelRequestStart(data) {
  return {
    type: ORDERS_SEND_CANCEL_REQUEST_START,
    data,
  };
}

export function ordersSendCancelRequestSuccess(data) {
  return {
    type: ORDERS_SEND_CANCEL_REQUEST_SUCCESS,
    data,
  };
}

export function ordersSendCancelRequestFailure(error) {
  return {
    type: ORDERS_SEND_CANCEL_REQUEST_FAILURE,
    error,
  };
}

//Return Order
export function ordersSendReturnRequestStart(data) {
  return {
    type: ORDERS_SEND_RETURN_REQUEST_START,
    data,
  };
}

export function ordersSendReturnRequestSuccess(data) {
  return {
    type: ORDERS_SEND_RETURN_REQUEST_SUCCESS,
    data,
  };
}

export function ordersSendReturnRequestFailure(error) {
  return {
    type: ORDERS_SEND_RETURN_REQUEST_FAILURE,
    error,


  }
}

//Orders Checkout
export function ordersCheckoutStart(data) {
  return {
    type: ORDERS_CHECKOUT_START,
    data,
  };
}

export function ordersCheckoutSuccess(data) {
  return {
    type: ORDERS_CHECKOUT_SUCCESS,
    data,
  };
}

export function ordersCheckoutFailure(error) {
  return {
    type: ORDERS_CHECKOUT_FAILURE,
    error,
  }
}