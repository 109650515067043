import React, { useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Image,
    InputGroup,
    Form,
    ProgressBar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./StaticPage.css";
import { useParams } from "react-router";
import { fetchStaticPageStart } from "../store/actions/PageAction";
import { connect } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import StaticPageLoader from "../Helper/StaticPageLoader";


const StaticPage = (props) => {

    const { static_page_unique_id } = useParams();

    useEffect(() => {
        if (static_page_unique_id) {
            props.dispatch(
                fetchStaticPageStart({ static_page_unique_id: static_page_unique_id })
            );
        }
        window.scrollTo(0, 0);
    }, [static_page_unique_id]);

    return (
        <>
            <div className="about-us-sec">
                {props.pageData.loading ?
                    <StaticPageLoader/>
                    : Object.keys(props.pageData.data).length > 0 ? (
                        <>
                            <div className="contact-sec">
                                <div className="contact-sec-bg">
                                    <div className="contact-banner-content">
                                        <h2>{props.pageData.data.title}</h2>
                                    </div>
                                </div>
                                <div>
                                </div>
                                <div className="about-us-sec sm-padding">
                                    <Container>
                                        <div className="about-us-box">
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: props.pageData.data.description,
                                                }}
                                            ></p>
                                        </div>
                                    </Container>
                                </div>
                            </div>
                        </>
                    ) : <NoDataFound />}

            </div>
        </>
    );
};

const mapStateToPros = (state) => ({
    pageData: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(StaticPage);