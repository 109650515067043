import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga";
import WalletSaga from './WalletSaga';
import PageSaga from './PageSaga';
import CartsSaga from './CartsSaga';
import OrdersSaga from './OrdersSaga';
import DeliverySaga from './DeliverySaga';
import HomePageSaga from './HomePageSaga';
import ReviewsSaga from './ReviewsSaga';
import ProductSaga from './productSaga';

export default function* rootSaga() {
  yield all([
    fork(UserSaga),
    fork(WalletSaga),
    fork(PageSaga),
    fork(CartsSaga),
    fork(OrdersSaga),
    fork(DeliverySaga),
    fork(HomePageSaga),
    fork(ReviewsSaga),
    fork(ProductSaga),
  ]);
}
