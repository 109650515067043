import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Verification.css";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { verifyEmailStart, regenerateEmailVerificationCodeStart } from "../store/actions/UserAction";
import configuration from "react-global-configuration";

const VerificationIndex = (props) => {
  const t = useTranslation();

  const navigate = useNavigate();
  const [otp, setOTP] = useState("");
  const [email, setEmail] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const handleChange = (otp) => setOTP(otp);

  useEffect(() => {
    if (localStorage.getItem("email")) {
      setEmail(localStorage.getItem("email"));
    } else {
      let userId = localStorage.getItem("userId");
      let accessToken = localStorage.getItem("accessToken");
      let userLoginStatus = localStorage.getItem("userLoginStatus");
      let authentication = userId && accessToken && userLoginStatus == "true" ? true : false;
      if (authentication) {
        navigate("/");
      } else {
        navigate("/register");
      }
    }
  }, []);

  const verify = e => {
    e.preventDefault();
    if (otp && otp.length === 6) {
      props.dispatch(verifyEmailStart({
        email: email,
        verification_code: otp,
      }));
    }
  };

  const resend = () => {
    props.dispatch(regenerateEmailVerificationCodeStart({
      email: email,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.verifyEmail.loading && Object.keys(props.verifyEmail.data).length > 0) {
      if (localStorage.getItem('product')) {
        let product = localStorage.getItem('product');
        localStorage.removeItem('product')
        navigate(`/product/${product}`);
      }
      else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [props.verifyEmail]);

  return (
    <>
      <div className="verification-sec">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={5}>
              <Link to="/login">
                <div className="verification-logo-sec">
                  <Image
                    className="verification-logo"
                    src={configuration.get("configData.site_logo")}
                  />
                </div>
              </Link>
              <div className="verification-box">
                <h4>
                {t("enter_one_time_password")}
                </h4>
                <p>{t("we_emailed_otp_to")} {email}
                </p>
                <Form onSubmit={verify}>
                  <div className="verification-item">
                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      separator={<span>-</span>}
                    />
                  </div>
                  <div className="verification-btn-sec">
                    <Button
                      className="wishlist-btn"
                      onClick={() => resend()}
                      disabled={props.regenerateEmailVerificationCode.buttonDisable}
                    >
                      {props.regenerateEmailVerificationCode.loadingButtonContent ?
                        props.regenerateEmailVerificationCode.loadingButtonContent
                        : t("resend")
                      }
                    </Button>
                    <Button
                      type="submit"
                      className="wishlist-btn"
                      disabled={otp.length < 6 && props.verifyEmail.buttonDisable}
                    >
                      {props.verifyEmail.loadingButtonContent ?
                        props.verifyEmail.loadingButtonContent
                        : t("validate")
                      }
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  verifyEmail: state.users.verifyEmail,
  regenerateEmailVerificationCode: state.users.regenerateEmailVerificationCode,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(VerificationIndex));
