import React from "react"
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Container,
  Row,
  Col,
  Image,
  InputGroup,
  Form,
  ProgressBar,
} from "react-bootstrap";
import Slider from "react-slick"

const BannerListLoader = (props) => (

  <>
    <Skeleton className="banner-loader" />
    {/* <Container>
      <div className="home-banner-content">

        <Slider >
          <div>
            <Row className="align-items-center">
              <Col md={6} className="resp-mrg-btm-xs">
                <div className="home-banner-info">
                  <h5><Skeleton width={160} height={20} /></h5>
                  <h1><Skeleton width={160} height={20} /></h1>
                  <p> <Skeleton width={160} height={20} /> </p>
                  <div className="default-btn-sec">
                    <Skeleton width={22} height={22} circle={true} className="default-btn" />
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="home-banner-img-sec">
                  <Skeleton width={140} height={140} className="home-banner-img" />
                </div>
              </Col>
            </Row>
          </div>
        </Slider>

      </div>
    </Container> */}

  </>
)

export default BannerListLoader;