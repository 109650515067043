import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import * as Yup from "yup";
import { userLoginStart, userRegisterStart } from "../store/actions/UserAction";
import configuration from "react-global-configuration";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import SocialButton from "../Helper/SocialButton";
import { gapi } from "gapi-script";

const LoginIndex = (props) => {
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("invalid_email")).required(t("email_required")),
    password: Yup.string()
      .required(t("password_required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")),
  });

  const handleLogin = (values) => {
    props.dispatch(userLoginStart(values));
  };

  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get("configData.GOOGLE_CLIENT_ID"),
        plugin_name: "chat",
      });
    });
  }, []);

  const handleFacebookLogin = (user) => {
    console.log("handleFacebookLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        email: user._profile.email ? user._profile.email : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "facebook",
        dob: localStorage.getItem("userDOB"),
      })
    );
  };

  const handleGoogleLogin = (user) => {
    console.log("handleGoogleLogin", user._profile);
    props.dispatch(
      userRegisterStart({
        name: user._profile.name,
        email: user._profile.email,
        first_name: user._profile.firstName ? user._profile.firstName : "",
        last_name: user._profile.lastName ? user._profile.lastName : "",
        social_unique_id: user._profile.id,
        picture: user._profile.profilePicURL,
        login_by: "google",
        dob: localStorage.getItem("userDOB"),
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      if (localStorage.getItem("product")) {
        let product = localStorage.getItem("product");
        localStorage.removeItem("product");
        navigate(`/product/${product}`);
      } else {
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !props.login.loading &&
      Object.keys(props.login.data).length > 0
    ) {
      if (props.login.data.is_two_step_auth_enabled == 1) {
        navigate("/verification");
      } else if (props.login.data.is_email_verified == 0) {
        navigate("/register-verification");
      } else {
        if (localStorage.getItem("product")) {
          let product = localStorage.getItem("product");
          localStorage.removeItem("product");
          navigate(`/product/${product}`);
        } else {
          navigate("/");
        }
      }
    }
    setSkipRender(false);
  }, [props.login]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.register.loading &&
      Object.keys(props.register.data).length > 0
    ) {
      if (props.register.data.is_email_verified === 0) {
        navigate("/register-verification");
      } else {
        if (localStorage.getItem("product")) {
          let product = localStorage.getItem("product");
          localStorage.removeItem("product");
          navigate(`/product/${product}`);
        } else {
          navigate("/");
        }
      }
    }
    setSkipRender(false);
  }, [props.register]);

  return (
    <>
      <div className="login-sec">
        <Container>
          <div className="login-title">
            {/* <h4>{t("already_registered")}</h4> */}
          </div>
          <Row className="align-items-center justify-content-center g-4">
            <Col xl={5} md={12} lg={5}>
              <div className="login-right-sec">
                <div className="login-img mobile-img">
                  <Image
                    src={window.location.origin + "/images/login-img.svg"}
                    type="image/png"
                  />
                </div>
                <div className="login-content">
                  <h4>{t("new_customer")}</h4>
                  <p>{t("login_text")}</p>
                  <Link to="/register" className="wishlist-btn">
                    {t("create_account")}
                  </Link>
                </div>
              </div>
            </Col>
            <Col xl={6} md={12} lg={7}>
              <div className="login-form">
                <h3>{t("login")}</h3>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    dob: localStorage.getItem("userDOB"),
                  }}
                  validationSchema={loginSchema}
                  onSubmit={(values) => handleLogin(values)}
                >
                  {({ touched, errors }) => (
                    <FORM>
                      <div className="required-content">
                        <p>{t("required_fields")}*</p>
                      </div>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t("email")}*</Form.Label>
                        <Field
                          type="email"
                          name="email"
                          placeholder={t("email_placeholder")}
                          className="form-control"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>{t("password")}*</Form.Label>

                        <div className="input-group d-flex align-items-center">
                          <Field
                            type={loginPasswordVisible ? "text" : "password"}
                            name="password"
                            placeholder={t("password_placeholder")}
                            className="form-control"
                          />
                          <div className="input-group-append">
                            <button
                              onClick={() =>
                                setLoginPasswordVisible(!loginPasswordVisible)
                              }
                              className="btn password-eye"
                              type="button"
                            >
                              {loginPasswordVisible ? (
                                <i className="fas fa-eye-slash align-self-center"></i>
                              ) : (
                                <i className="fas fa-eye align-self-center"></i>
                              )}
                            </button>
                          </div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="password"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <div className="login-btn default-btn-sec">
                        <Button
                          className="default-btn"
                          type="submit"
                          disabled={props.login.buttonDisable}
                        >
                          {props.login.loadingButtonContent
                            ? props.login.loadingButtonContent
                            : t("login")}
                        </Button>
                        <Link to="/forgot-password">{t("lost_password?")}</Link>
                      </div>
                    </FORM>
                  )}
                </Formik>
                <div className="login-social-icon mt-3">
                  {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                    configuration.get("configData.FB_CLIENT_ID") ? (
                    <span className="or-line">
                      <p>{t("login_with")}</p>
                    </span>
                  ) : null}

                  <div className="login-icons">
                    <ul>
                      <li>
                        {configuration.get("configData.FB_CLIENT_ID") ? (
                          <SocialButton
                            provider="facebook"
                            appId={configuration.get("configData.FB_CLIENT_ID")}
                            onLoginSuccess={handleFacebookLogin}
                            onLoginFailure={handleSocialLoginFailure}
                            className="social-button"
                            id="facebook-connect"
                          >
                            <span>
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/facebook.svg"
                                }
                              />
                            </span>
                          </SocialButton>
                        ) : null}
                      </li>
                      <li>
                        {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                          <SocialButton
                            provider="google"
                            key={"google"}
                            appId={configuration.get(
                              "configData.GOOGLE_CLIENT_ID"
                            )}
                            onLoginSuccess={handleGoogleLogin}
                            onLoginFailure={handleSocialLoginFailure}
                            className="social-button"
                            id="google-connect"
                          >
                            <span>
                              <Image
                                src={
                                  window.location.origin + "/images/google.svg"
                                }
                              />
                            </span>
                          </SocialButton>
                        ) : null}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
  register: state.users.register,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LoginIndex));
