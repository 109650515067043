import {
  SAVE_CART_DETAILS_START,
  SAVE_CART_DETAILS_SUCCESS,
  SAVE_CART_DETAILS_FAILURE,
  FETCH_CART_LIST_START,
  FETCH_CART_LIST_SUCCESS,
  FETCH_CART_LIST_FAILURE,
  DELETE_CART_DETAILS_START,
  DELETE_CART_DETAILS_SUCCESS,
  DELETE_CART_DETAILS_FAILURE,
  GUEST_CART_LIST,
  FETCH_GUEST_CART_LIST_START,
  FETCH_GUEST_CART_LIST_SUCCESS,
  FETCH_GUEST_CART_LIST_FAILURE,
  UPDATE_GUEST_CART_LIST_START,
} from "./ActionConstant";

//cart save

export function saveCartDetailsStart(data) {
  return {
    type: SAVE_CART_DETAILS_START,
    data,
  };
}

export function saveCartDetailsSuccess(data) {
  return {
    type: SAVE_CART_DETAILS_SUCCESS,
    data,
  };
}

export function saveCartDetailsFailure(error) {
  return {
    type: SAVE_CART_DETAILS_FAILURE,
    error,
  };
}

// card list

export function fetchCartListStart(data) {
  return {
    type: FETCH_CART_LIST_START,
    data,
  };
}

export function fetchCartListSuccess(data) {
  return {
    type: FETCH_CART_LIST_SUCCESS,
    data,
  };
}

export function fetchCartListFailure(error) {
  return {
    type: FETCH_CART_LIST_FAILURE,
    error,
  };
}

// cart delete
export function deleteCartDetailsStart(data) {
  return {
    type: DELETE_CART_DETAILS_START,
    data,
  };
}

export function deleteCartDetailsSuccess(data) {
  return {
    type: DELETE_CART_DETAILS_SUCCESS,
    data,
  };
}

export function deleteCartDetailsFailure(error) {
  return {
    type: DELETE_CART_DETAILS_FAILURE,
    error,
  };
}

export function guestCartList(data) {
  return {
    type: GUEST_CART_LIST,
    data,
  }
}

// Guest User Cart
export function fetchGuestCartListStart(data) {
  return {
    type: FETCH_GUEST_CART_LIST_START,
    data,
  };
}

export function updateGuestCartListStart(data) {
  return {
    type: UPDATE_GUEST_CART_LIST_START,
    data,
  }
}

export function fetchGuestCartListSuccess(data) {
  return {
    type: FETCH_GUEST_CART_LIST_SUCCESS,
    data,
  };
}

export function fetchGuestCartListFailure(error) {
  return {
    type: FETCH_GUEST_CART_LIST_FAILURE,
    error,
  }
}