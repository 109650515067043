import React from "react";
import {
  Col,
  Container,
  Row,
  Accordion,
  Image,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import "./Checkout.css";

const CheckOutOldIndex = () => {


  const t = useTranslation();

  return (
    <>
      <div className="checkout-sec">
        <Container>
          <Row>
            <Col xl={8} md={12}>
              <div className="checkout-payment-details">
                <h4>{t("shipping")}</h4>
              </div>
              <div className="checkout-dropdown">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="checkout-label-details shipping-deatils">
                        <h4>John Mathew Home</h4>
                        <p>
                          P.O. Box 132 1599 Curabitur Rd. Bandera South Dakota
                          45149
                        </p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="address-label-box">
                        <div className="address-radio-btn">
                          <input
                            name="address"
                            class="radio"
                            type="radio"
                            id="card"
                            checked
                          />
                          <label
                            name="address"
                            class="radio"
                            type="radio"
                            for="card"
                            checked
                          >
                            <div className="saved-address-box">
                              <div className="address-details">
                                <h4>John Mathew Home</h4>
                                <p>
                                  P.O. Box 132 1599 Curabitur Rd. Bandera South
                                  Dakota 45149 (959) 119-8364
                                </p>
                              </div>
                              <div className="address-btn">
                                <Link to="">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/edit-black.svg"
                                    }
                                    type="image/png"
                                  />
                                </Link>
                                <Link to="" className="address-button">
                                  Use This Address
                                </Link>
                              </div>
                            </div>
                          </label>
                        </div>
                        <div className="address-radio-btn label-bg">
                          <input
                            name="address"
                            class="radio"
                            type="radio"
                            id="card-1"
                            checked
                          />
                          <label
                            name="address"
                            class="radio"
                            type="radio"
                            for="card-1"
                            checked
                          >
                            <div className="saved-address-box">
                              <div className="address-details">
                                <h4>John Mathew Home</h4>
                                <p>
                                  P.O. Box 132 1599 Curabitur Rd. Bandera South
                                  Dakota 45149 (959) 119-8364
                                </p>
                              </div>
                              <div className="address-btn">
                                <Link to="">
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/images/edit-black.svg"
                                    }
                                    type="image/png"
                                  />
                                </Link>
                                <Link to="" className="address-button">
                                  Use This Address
                                </Link>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="checkout-payment-details mt-5">
                <h4>Payment Method </h4>
              </div>
              <div className="checkout-dropdown ">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="checkout-label-details">
                        <Image
                          src={window.location.origin + "/images/express.png"}
                          type="image/png"
                        />
                        <h4>41** **** **** 1425</h4>
                        <p>John Mathew</p>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="payment-radio-box">
                        <div className="payment-radio-btn">
                          <input
                            name="plan"
                            class="radio"
                            type="radio"
                            checked
                            id="payment"
                          />
                          <label
                            name="plan"
                            class="radio"
                            type="radio"
                            checked
                            for="payment"
                          >
                            <p>
                              <Image
                                src={
                                  window.location.origin + "/images/visa.png"
                                }
                                type="image/png"
                              />
                            </p>
                            <p>41xx xxxx xxxx 5609</p>
                            <p>John Mathew</p>
                            <p>10/2022</p>
                            <Link to="" className="card-edit-icon">
                              <span>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/delete-1.svg"
                                  }
                                  type="image/png"
                                />
                              </span>
                              Delete
                            </Link>
                            <div></div>
                          </label>
                        </div>

                        <div className="payment-radio-btn label-bg">
                          <input
                            name="plan"
                            class="radio"
                            type="radio"
                            checked
                            id="payment-1"
                          />
                          <label
                            name="plan"
                            class="radio"
                            type="radio"
                            checked
                            for="payment-1"
                          >
                            <p>
                              <Image
                                src={
                                  window.location.origin + "/images/express.png"
                                }
                                type="image/png"
                              />
                            </p>
                            <p>41xx xxxx xxxx 5609</p>
                            <p>John Mathew</p>
                            <p>10/2022</p>
                            <Link to="" className="card-edit-icon">
                              <span>
                                <Image
                                  src={
                                    window.location.origin +
                                    "/images/delete-1.svg"
                                  }
                                  type="image/png"
                                />
                              </span>
                              Delete
                            </Link>
                            <div></div>
                          </label>
                        </div>
                        <div className="payment-radio-btn label-bg dropdown-wallet">
                          <input
                            name="plan"
                            class="radio"
                            type="radio"
                            checked
                            id="payment-2"
                          />
                          <label
                            name="plan"
                            class="radio"
                            type="radio"
                            checked
                            for="payment-2"
                          >
                            <p>
                              <Image
                                src={
                                  window.location.origin + "/images/wallet.svg"
                                }
                                type="image/png"
                              />
                              <span>Wallet</span>
                            </p>
                            <p>411215566545609</p>
                            <p>234.94 $</p>
                          </label>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
            <Col xl={4} md={12}>
              <div className="checkout-box">
                <div className="cart-box">
                  <div className="cart-header-sec">
                    <h4>Order Summary</h4>
                    <Link to="">
                      <p>
                        <span>
                          <Image
                            src={window.location.origin + "/images/edit.svg"}
                            type="image/png"
                          />
                        </span>
                        Edit Cart
                      </p>
                    </Link>
                  </div>

                  <div className="cart-body-sec">
                    <div className="checkout-order-details">
                      <div className="checkout-product-box">
                        <div className="checkout-product-img">
                          <Image
                            src={window.location.origin + "/images/item-1.png"}
                            type="image/png"
                          />
                        </div>
                        <div className="checkout-product-content">
                          <h4>250MG CBD PET DROPS</h4>
                          <p>
                            QTY<span>01</span>
                          </p>
                          <h5>
                            264 $ <span>364 $</span>
                          </h5>
                        </div>
                      </div>
                      <div className="checkout-product-box mt-4">
                        <div className="checkout-product-img order-bg-color">
                          <Image
                            src={window.location.origin + "/images/item-1.png"}
                            type="image/png"
                          />
                        </div>
                        <div className="checkout-product-content">
                          <h4>250MG CBD PET DROPS</h4>
                          <p>
                            QTY<span>01</span>
                          </p>
                          <h5>
                            264 $ <span>364 $</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="cart-summary-info mt-3">
                      <div className="cart-summary-header">
                        <p>
                          Cart Total <span>1230.87 $</span>
                        </p>
                        <p>
                          Discount <span>-89.00 $</span>
                        </p>
                      </div>
                      <div className="cart-summary-total-amount">
                        <h5>
                          Order Total <span>1,430.00 $</span>
                        </h5>
                      </div>
                      <div className="cart-summary-btn-sec">
                        <Link to="/order-placed" className="place-order-btn">
                          Check Out
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="continue-shopping-btn-sec">
                    <Link
                      to="/shop"
                      className="continue-shopping-btn"
                    >
                      Continue Shopping
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default (withTranslation(CheckOutOldIndex));
