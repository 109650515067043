import React, { useState, useEffect } from 'react';
import AddCardSection from './AddCardSection';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchUserCardListStart } from '../store/actions/WalletAction';
import { withTranslation, useTranslation } from "react-multi-lang";
import SomethingWrong from '../Helper/SomethingWrong';
import NoDataFound from '../Helper/NoDataFound';
import SingleCard from './SingleCard';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import NoCardDetailsFound from '../Helper/NoCardDetailsFound';


const PaymentSection = (props) => {
  const [skipFirst, setSkipFirst] = useState(true);
  const t = useTranslation();

  useEffect(() => {
    props.dispatch(fetchUserCardListStart());
  }, []);

  useEffect(() => {
    if (!skipFirst && !props.userCardList.loading && Object.keys(props.userCardList.data).length > 0) {
      if (props.userCardList.data.user_cards && props.userCardList.data.user_cards.length > 0) {
        props.userCardList.data.user_cards.map((card, i) => {
          if (card.is_default === 1)
            props.setSelectedCard(card.user_card_id);
        })
      } else {
        props.setSelectedCard(null);
      }
    }
    setSkipFirst(false);
  }, [props.userCardList]);

  useEffect(() => {
    if (!skipFirst && !props.addUserCard.loading && Object.keys(props.addUserCard.data).length > 0) {
      props.dispatch(fetchUserCardListStart());
    }
  }, [props.addUserCard]);

  return (
    <div className="payment-radio-box">
      {props.userCardList.loading ?
        <Skeleton
          count={2}
          height={100}
        />
        : props.userCardList.data.user_cards ?
          props.userCardList.data.user_cards.length > 0 ?
            props.userCardList.data.user_cards.map((card, i) =>
              <SingleCard
                card={card}
                key={i}
                selectedCard={props.selectedCard}
                setSelectedCard={props.setSelectedCard}
              />
            )
            : <NoCardDetailsFound/>
          : <SomethingWrong />
      }

      {/* <div className="mt-3">
                    <Link to="" className="address-button">
                      Add New Card
                    </Link>
                  </div> */}
      <div className="profile-details-title">
        <h4>{t("add_new_card")}</h4>
      </div>
      <AddCardSection />
    </div >
  );
}

const mapStateToPros = (state) => ({
  addUserCard: state.wallet.addUserCard,
  userCardList: state.wallet.userCardList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(PaymentSection));