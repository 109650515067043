import {
  SAVE_REVIEWS_START,
  SAVE_REVIEWS_SUCCESS,
  SAVE_REVIEWS_FAILURE,
  SAVE_REVIEWS_FILES_START,
  SAVE_REVIEWS_FILES_SUCCESS,
  SAVE_REVIEWS_FILES_FAILURE,
  DELETE_REVIEWS_FILES_START,
  DELETE_REVIEWS_FILES_SUCCESS,
  DELETE_REVIEWS_FILES_FAILURE,
  FETCH_REVIEWS_LIST_START,
  FETCH_REVIEWS_LIST_SUCCESS,
  FETCH_REVIEWS_LIST_FAILURE,
  DELETE_REVIEWS_START,
  DELETE_REVIEWS_SUCCESS,
  DELETE_REVIEWS_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  reviewsSave: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
  reviewsSaveFiles: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
  reviewsDeleteFiles: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
  reviewsList: {
		data: {},
		loading: true,
		error: false,
	},
  reviewsDelete: {
		data: {},
		loading: true,
		error: false,
		loadingButtonContent: "",
		buttonDisable: false
	},
};

const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_REVIEWS_START:
			return {
				...state,
				reviewsSave: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Uploading....",
					buttonDisable: true
				},
			};
		case SAVE_REVIEWS_SUCCESS:
			return {
				...state,
				reviewsSave: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SAVE_REVIEWS_FAILURE:
			return {
				...state,
				reviewsSave: {
					data: {},
					loading: true,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
      case SAVE_REVIEWS_FILES_START:
			return {
				...state,
				reviewsSaveFiles: {
					data: {},
					loading: true,
					error: false,
					loadingButtonContent: "Uploading....",
					buttonDisable: true
				},
			};
		case SAVE_REVIEWS_FILES_SUCCESS:
			return {
				...state,
				reviewsSaveFiles: {
					data: action.data,
					loading: false,
					error: false,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
		case SAVE_REVIEWS_FILES_FAILURE:
			return {
				...state,
				reviewsSaveFiles: {
					data: {},
					loading: true,
					error: action.error,
					loadingButtonContent: "",
					buttonDisable: false
				},
			};
      case DELETE_REVIEWS_FILES_START:
				return {
					...state,
					reviewsDeleteFiles: {
						data: {},
						loading: true,
						error: false,
						loadingButtonContent: "Uploading....",
						buttonDisable: true
					},
				};
			case DELETE_REVIEWS_FILES_SUCCESS:
				return {
					...state,
					reviewsDeleteFiles: {
						data: action.data,
						loading: false,
						error: false,
						loadingButtonContent: "",
						buttonDisable: false
					},
				};
			case DELETE_REVIEWS_FILES_FAILURE:
				return {
					...state,
					reviewsDeleteFiles: {
						data: {},
						loading: true,
						error: action.error,
						loadingButtonContent: "",
						buttonDisable: false
					},
				};
      case FETCH_REVIEWS_LIST_START:
			return {
				...state,
				reviewsList: {
					data: {},
					loading: true,
					error: false,
				},
			};
		case FETCH_REVIEWS_LIST_SUCCESS:
			return {
				...state,
				reviewsList: {
					data: action.data,
					loading: false,
					error: false,
				},
			};
		case FETCH_REVIEWS_LIST_FAILURE:
			return {
				...state,
				reviewsList: {
					data: {},
					loading: true,
					error: action.error,
				},
			};
      case DELETE_REVIEWS_START:
				return {
					...state,
					reviewsDelete: {
						data: {},
						loading: true,
						error: false,
						loadingButtonContent: "Uploading....",
						buttonDisable: true
					},
				};
			case DELETE_REVIEWS_SUCCESS:
				return {
					...state,
					reviewsDelete: {
						data: action.data,
						loading: false,
						error: false,
						loadingButtonContent: "",
						buttonDisable: false
					},
				};
			case DELETE_REVIEWS_FAILURE:
				return {
					...state,
					reviewsDelete: {
						data: {},
						loading: true,
						error: action.error,
						loadingButtonContent: "",
						buttonDisable: false
					},
				};
    default:
      return state;
  }
}

export default reviewsReducer;
