import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { FETCH_STATIC_PAGE_START } from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  fetchStaticPageSuccess,
  fetchStaticPageFailure,
} from "../actions/PageAction";

function* fetchStaticPage(action) {
  try {
    const response = yield api.postMethod(
      "static_pages_view", action.data
    );
    if (response.data.success) {
      yield put(fetchStaticPageSuccess(response.data.data));
    } else {
      yield put(fetchStaticPageFailure(response.data.error));
      // yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchStaticPageFailure(error));
    // yield call(getErrorNotificationMessage, error);
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_STATIC_PAGE_START, fetchStaticPage)]);
}
