import {
  SAVE_REVIEWS_START,
  SAVE_REVIEWS_SUCCESS,
  SAVE_REVIEWS_FAILURE,
  SAVE_REVIEWS_FILES_START,
  SAVE_REVIEWS_FILES_SUCCESS,
  SAVE_REVIEWS_FILES_FAILURE,
  DELETE_REVIEWS_FILES_START,
  DELETE_REVIEWS_FILES_SUCCESS,
  DELETE_REVIEWS_FILES_FAILURE,
  FETCH_REVIEWS_LIST_START,
  FETCH_REVIEWS_LIST_SUCCESS,
  FETCH_REVIEWS_LIST_FAILURE,
  DELETE_REVIEWS_START,
  DELETE_REVIEWS_SUCCESS,
  DELETE_REVIEWS_FAILURE,
} from "./ActionConstant";


export function saveReviewsStart(data) {
  return {
    type: SAVE_REVIEWS_START,
    data,
  };
}

export function saveReviewsSuccess(data) {
  return {
    type: SAVE_REVIEWS_SUCCESS,
    data,
  };
}

export function saveReviewsFailure(error) {
  return {
    type: SAVE_REVIEWS_FAILURE,
    error,
  };
}

export function saveReviewsFilesStart(data) {
  return {
    type: SAVE_REVIEWS_FILES_START,
    data,
  };
}

export function saveReviewsFilesSuccess(data) {
  return {
    type: SAVE_REVIEWS_FILES_SUCCESS,
    data,
  };
}

export function saveReviewsFilesFailure(error) {
  return {
    type: SAVE_REVIEWS_FILES_FAILURE,
    error,
  };
}


export function deleteReviewsFilesStart(data) {
  return {
    type: DELETE_REVIEWS_FILES_START,
    data,
  };
}

export function deleteReviewsFilesSuccess(data) {
  return {
    type: DELETE_REVIEWS_FILES_SUCCESS,
    data,
  };
}

export function deleteReviewsFilesFailure(error) {
  return {
    type: DELETE_REVIEWS_FILES_FAILURE,
    error,
  };
}

export function fetchReviewsListStart(data) {
  return {
    type: FETCH_REVIEWS_LIST_START,
    data,
  };
}

export function fetchReviewsListSuccess(data) {
  return {
    type: FETCH_REVIEWS_LIST_SUCCESS,
    data,
  };
}

export function fetchReviewsListFailure(error) {
  return {
    type: FETCH_REVIEWS_LIST_FAILURE,
    error,
  };
}

export function deleteReviewsStart(data) {
  return {
    type: DELETE_REVIEWS_START,
    data,
  };
}

export function deleteReviewsSuccess(data) {
  return {
    type: DELETE_REVIEWS_SUCCESS,
    data,
  };
}

export function deleteReviewsFailure(error) {
  return {
    type: DELETE_REVIEWS_FAILURE,
    error,
  };
}

