import { call, select, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  updateUserDetailsSuccess,
  userLoginSuccess,
  userLoginFailure,
  updateUserDetailsFailure,
  userRegisterSuccess,
  userRegisterFailure,
  forgotPasswordSuccess,
  forgotPassworFailure,
  logoutSuccess,
  logoutFailure,
  regenerateEmailVerificationCodeSuccess,
  regenerateEmailVerificationCodeFailure,
  verifyEmailSuccess,
  verifyEmailFailure,
  twoStepAuthLoginSuccess,
  twoStepAuthLoginFailure,
  twoStepAuthResendCodeSuccess,
  twoStepAuthResendCodeFailure,
  changePasswordSuccess,
  changePasswordFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  twoStepAuthUpdateSuccess,
  twoStepAuthUpdateFailure,
  fetchDashboardSuccess,
  fetchDashboardFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  usernameValidationSuccess,
  usernameValidationFailure,
  fetchSalesReportSuccess,
  fetchSalesReportFailure,
} from "../actions/UserAction";

import api from "../../Environment";
import {
  FETCH_USER_DETAILS_START,
  UPDATE_USER_DETAILS_START,
  LOGIN_START,
  REGISTER_START,
  FORGOT_PASSWORD_START,
  REGENERATE_EMAIL_VERIFICATION_CODE_START,
  VERIFY_EMAIL_START,
  TWO_STEP_AUTH_LOGIN_START,
  TWO_STEP_AUTH_RESEND_CODE_START,
  CHANGE_PASSWORD_START,
  DELETE_ACCOUNT_START,
  TWO_STEP_AUTH_UPDATE_START,
  FETCH_DASHBOARD_START,
  LOGOUT_START,
  RESET_PASSWORD_START,
  USERNAME_VALIDATION_START,
  FETCH_SALES_REPORT_START,
} from "../actions/ActionConstant";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";

function* getUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("profile");

    if (response.data.success) {
      yield put(fetchUserDetailsSuccess(response.data.data));
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem(
        "is_subscription_enabled",
        response.data.data.is_subscription_enabled
      );
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
    } else {
      yield put(fetchUserDetailsFailure(response.data));
      yield call(getErrorNotificationMessage, response.data.error);
      action.data.navigate("/logout");
    }
  } catch (error) {
    yield put(fetchUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* updateUserDetailsAPI(action) {
  try {
    const response = yield api.postMethod("update_profile", action.data);
    if (response.data.success) {
      yield put(updateUserDetailsSuccess(response.data.data));
      yield put(fetchUserDetailsSuccess(response.data.data));
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("user_unique_id", response.data.data.user_unique_id);
      localStorage.setItem("user_cover", response.data.data.cover);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem("is_model", response.data.data.is_model);
      localStorage.setItem(
        "is_document_verified",
        response.data.data.is_document_verified
      );
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield call(getErrorNotificationMessage, response.data.error);
      yield put(updateUserDetailsFailure(response.data.error));
    }
  } catch (error) {
    yield put(updateUserDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userLoginAPI() {
  try {
    const userData = yield select((state) => state.users.loginInputData.data);
    const jsonResponse = yield api.getLocation();
    const response = yield api.postMethod("login", {
      ...userData,
      country_code: jsonResponse.country ? jsonResponse.country : '',
      state: jsonResponse.region ? jsonResponse.region : '',
    });

    if (response.data.success) {
      yield put(userLoginSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (response.data.code == 1001 || response.data.data.is_two_step_auth_enabled === 1) {
        localStorage.setItem("email", response.data.data.email);
      } else {
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("user_picture", response.data.data.picture);
        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("username", response.data.data.username);
        localStorage.setItem(
          "user_unique_id",
          response.data.data.user_unique_id
        );
        localStorage.setItem("userId", response.data.data.user_id);
        localStorage.setItem("accessToken", response.data.data.token);
      }
    } else {
      yield put(userLoginFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userLoginFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* userRegisterAPI(action) {
  try {
    const jsonResponse = yield api.getLocation();
    const response = yield api.postMethod("register", {
      ...action.data,
      country_code: jsonResponse.country ? jsonResponse.country : '',
      state: jsonResponse.region ? jsonResponse.region : '',
    });
    if (response.data.success) {
      yield put(userRegisterSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      if (response.data.code == 1001) {
        localStorage.setItem("email", response.data.data.email);
      } else {
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("userId", response.data.data.user_id);
        localStorage.setItem("accessToken", response.data.data.token);
      }
    } else {
      yield put(userRegisterFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(userRegisterFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* forgotPasswordAPI(action) {
  try {
    const response = yield api.postMethod("forgot_password", action.data);

    if (response.data.success) {
      yield put(forgotPasswordSuccess(response.data));
      // localStorage.removeItem("userId");
      // localStorage.removeItem("accessToken");
      // window.location.assign("/login");
    } else {
      yield put(forgotPassworFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(forgotPassworFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* logoutAPI(action) {
  try {
    const response = yield api.postMethod("logout", action.data);
    if (response.data.success) {
      yield put(logoutSuccess(response.data));
      localStorage.removeItem("userId");
      localStorage.removeItem("accessToken");
      window.location.assign("/login");
    } else {
      yield put(logoutFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(logoutFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* regenerateEmailVerificationCodeAPI(action) {
  try {
    const response = yield api.postMethod("resend_email_verification_code", action.data);
    if (response.data.success) {
      yield put(regenerateEmailVerificationCodeSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(regenerateEmailVerificationCodeFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(regenerateEmailVerificationCodeFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* verifyEmailAPI(action) {
  try {
    const response = yield api.postMethod("verify_email", action.data);
    if (response.data.success) {
      yield put(verifyEmailSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem(
        "user_unique_id",
        response.data.data.user_unique_id
      );
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
    } else {
      yield put(verifyEmailFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(verifyEmailFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* twoStepAuthLoginAPI(action) {
  try {
    const response = yield api.postMethod("two_step_auth_login", action.data);
    if (response.data.success) {
      yield put(twoStepAuthLoginSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      localStorage.removeItem("email")
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("user_picture", response.data.data.picture);
      localStorage.setItem("name", response.data.data.name);
      localStorage.setItem("username", response.data.data.username);
      localStorage.setItem(
        "user_unique_id",
        response.data.data.user_unique_id
      );
      localStorage.setItem("userId", response.data.data.user_id);
      localStorage.setItem("accessToken", response.data.data.token);
    } else {
      yield put(twoStepAuthLoginFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(twoStepAuthLoginFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* twoStepAuthResendCodeAPI(action) {
  try {
    const response = yield api.postMethod("resend_two_step_auth_code", action.data);
    if (response.data.success) {
      yield put(twoStepAuthResendCodeSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(twoStepAuthResendCodeFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(twoStepAuthResendCodeFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* changePasswordAPI(action) {
  try {
    const response = yield api.postMethod("change_password", action.data);
    if (response.data.success) {
      yield put(changePasswordSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(changePasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(changePasswordFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* deleteAccountAPI(action) {
  try {
    const response = yield api.postMethod("delete_account", action.data);
    if (response.data.success) {
      yield put(deleteAccountSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(deleteAccountFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteAccountFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* twoStepAuthUpdateAPI(action) {
  try {
    const response = yield api.postMethod("two_step_auth_update", action.data);
    if (response.data.success) {
      yield put(twoStepAuthUpdateSuccess(response.data));
      yield put(fetchUserDetailsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(twoStepAuthUpdateFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(twoStepAuthUpdateFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchDashboardAPI(action) {
  try {
    const response = yield api.postMethod("dashboard", action.data);
    if (response.data.success) {
      yield put(fetchDashboardSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchDashboardFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchDashboardFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* resetPasswordAPI(action) {
  try {
    const response = yield api.postMethod("reset_password", action.data);
    if (response.data.success) {
      yield put(resetPasswordSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(resetPasswordFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(resetPasswordFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* usernameValidationAPI(action) {
  try {
    const response = yield api.postMethod("username_validation", action.data);
    if (response.data.success) {
      yield put(usernameValidationSuccess(response.data));
      // yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(usernameValidationFailure(response.data.error));
      // yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(usernameValidationFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSalesReportAPI(action) {
  try {
    const response = yield api.postMethod("sales_report", action.data);
    if (response.data.success) {
      yield put(fetchSalesReportSuccess(response.data));
      // yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(fetchSalesReportFailure(response.data.error));
      // yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSalesReportFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_USER_DETAILS_START, getUserDetailsAPI),
    yield takeLatest(UPDATE_USER_DETAILS_START, updateUserDetailsAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(REGISTER_START, userRegisterAPI),
    yield takeLatest(FORGOT_PASSWORD_START, forgotPasswordAPI),
    yield takeLatest(LOGOUT_START, logoutAPI),
    yield takeLatest(REGENERATE_EMAIL_VERIFICATION_CODE_START, regenerateEmailVerificationCodeAPI),
    yield takeLatest(VERIFY_EMAIL_START, verifyEmailAPI),
    yield takeLatest(TWO_STEP_AUTH_LOGIN_START, twoStepAuthLoginAPI),
    yield takeLatest(TWO_STEP_AUTH_RESEND_CODE_START, twoStepAuthResendCodeAPI),
    yield takeLatest(CHANGE_PASSWORD_START, changePasswordAPI),
    yield takeLatest(DELETE_ACCOUNT_START, deleteAccountAPI),
    yield takeLatest(TWO_STEP_AUTH_UPDATE_START, twoStepAuthUpdateAPI),
    yield takeLatest(FETCH_DASHBOARD_START, fetchDashboardAPI),
    yield takeLatest(RESET_PASSWORD_START, resetPasswordAPI),
    yield takeLatest(USERNAME_VALIDATION_START, usernameValidationAPI),
    yield takeLatest(FETCH_SALES_REPORT_START, fetchSalesReportAPI),
  ]);
}
