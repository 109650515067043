import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'react-bootstrap';
import { ordersSendReturnRequestStart } from '../store/actions/OrdersAction';
import { connect } from 'react-redux';

const OrderReturnModal = (props) => {

  const [reason, setReason] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const returnOrder = e => {
    e.preventDefault();
    if (reason) {
      props.dispatch(ordersSendReturnRequestStart({
        order_unique_id: props.orderUniqueId,
        cancelled_reason: reason,
      }));
    }
  }

  useEffect(() => {
    if (!skipRender && !props.returnOrder.loading && Object.keys(props.returnOrder.data).length > 0) {
      props.closeOrderReturnModal();
    }
    setSkipRender(false);
  }, [props.returnOrder]);


  return (
    <>
      <Modal
        className="modal-dialog-center write-review-modal"
        size="md"
        centered
        show={props.orderReturnModal}
        onHide={props.closeOrderReturnModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Return</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="write-review-form" onSubmit={returnOrder}>
            <Row>
              <Col className="mb-3">
                <Form.Control
                  type="text"
                  as={"textarea"}
                  className="form-control"
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                  rows={4}
                  placeholder={"Reason"}
                />
              </Col>
              <Button
                className="wishlist-btn"
                type="submit"
                disabled={props.returnOrder.buttonDisable}
              >
                {props.returnOrder.loadingButtonContent ?
                  props.returnOrder.loadingButtonContent
                  : "Submit"
                }
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}


const mapStateToPros = (state) => ({
  returnOrder: state.orders.returnOrder
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(OrderReturnModal);