import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import { guestCartList } from "../../store/actions/CartsAction";
import SearchSuggetion from "../../Helper/SearchSuggetion";
import { fetchProductSuggetionStart } from "../../store/actions/ProductAction";

const HeaderIndex = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const t = useTranslation();

  const [search, setSearch] = useState("");
  const [showSuggetions, setShowSuggetions] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      props.dispatch(fetchUserDetailsStart({ navigate }));
    } else {
      props.dispatch(
        guestCartList(
          localStorage.getItem("carts")
            ? JSON.parse(localStorage.getItem("carts"))
            : []
        )
      );
    }
  }, []);

  const getSuggetions = (e) => {
    e.preventDefault();
    const searchValue = e.target.value;
    setSearch(searchValue);
    if (searchValue) {
      props.dispatch(
        fetchProductSuggetionStart({
          search_key: searchValue,
        })
      );
      setShowSuggetions(true);
    } else {
      setShowSuggetions(false);
    }
  };

  const searchProduct = (e) => {
    e.preventDefault();
    setShowSuggetions(false);
    navigate(`/shop/${search}`);
  };

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">
              <Image
                className="lazyload logo"
                src={configuration.get("configData.site_logo")}
                type="image/png"
                alt={configuration.get("configData.site_name")}
              />
            </Link>
            <div className="user-dropdown-sec">
              {!localStorage.getItem("userId") ||
                !localStorage.getItem("accessToken") ? (
                <div className="mobile-nav-profile ">
                  <Link to="/login">
                    {/* <span>
                           <Image
                             className="header-dropdown-item-icon"
                             src={
                               window.location.origin +
                               "/images/icons/sign-in-icon.svg"
                             }
                           />
                         </span> */}
                    <Button className="subscribe-btn" type="submit">
                      {t("sign_in")}
                    </Button>
                  </Link>
                </div>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="mobile-nav-profile contact-address-img ">
                      <Image
                        className="header-user-icon"
                        src={window.location.origin + "/images/user.svg"}
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link to="/cart">
                      <span>
                        <Image
                          className="header-dropdown-item-icon"
                          src={
                            window.location.origin +
                            "/images/icons/shopping-cart.svg"
                          }
                        />
                      </span>
                      {t("view_cart")}
                    </Link>
                    <Link to="/wishlist">
                      <span>
                        <Image
                          className="header-dropdown-item-icon"
                          src={
                            window.location.origin +
                            "/images/icons/wishlist.svg"
                          }
                        />
                      </span>
                      {t("wishlist")}
                    </Link>
                    <Link to="/profile">
                      <span>
                        <Image
                          className="header-user-icon"
                          src={window.location.origin + "/images/user.svg"}
                        />
                      </span>
                      {t("profile")}
                    </Link>
                    <Link to="/logout">
                      <span>
                        <Image
                          className="header-user-icon"
                          src={
                            window.location.origin + "/images/logout-img.svg"
                          }
                        />
                      </span>
                      {t("logout")}
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav mobile-toggle-sec">
              <Image
                className="lazyload hamburger-menu"
                src={window.location.origin + "/images/hamburger-menu.png"}
                type="image/png"
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav" className="new-header-sec">
              <Nav className="new-navbar-sec m-auto">
                <Link
                  to="/"
                  className={`nav-link ${location.pathname === "/" ? "active" : ""
                    }`}
                >
                  {t("home")}
                </Link>
                <Link
                  to="/shop"
                  className={`nav-link ${location.pathname === "/shop" ? "active" : ""
                    }`}
                >
                  {t("shop")}
                </Link>
                <Link
                  to="/about-us"
                  className={`nav-link ${location.pathname === "/about-us" ? "active" : ""
                    }`}
                >
                  {t("about")}
                </Link>
                <Link
                  to="/contact-us"
                  className={`nav-link ${location.pathname === "/contact-us" ? "active" : ""
                    }`}
                >
                  {t("contact")}
                </Link>
              </Nav>
              <Nav className="header-search-sec ms-auto">
                <div className="new-header-search">
                  <Form onSubmit={searchProduct}>
                    <InputGroup className="mb-0">
                      <InputGroup.Text id="basic-addon2">
                        <Image
                          className="header-search-icon"
                          src={
                            window.location.origin + "/images/icons/search.svg"
                          }
                        />
                      </InputGroup.Text>
                      <Form.Control
                        value={search}
                        onChange={getSuggetions}
                        placeholder={t("search_placeholder")}
                        onBlur={() => {
                          setTimeout(() => {
                            setShowSuggetions(false);
                          }, 500);
                        }}
                      />
                      <InputGroup.Text id="basic-addon2">
                        {search
                          ? <Image
                            className="header-search-icon"
                            src={
                              window.location.origin + "/images/close.svg"
                            }
                            onClick={() => setSearch("")}
                          />
                          : null
                        }
                      </InputGroup.Text>
                    </InputGroup>
                  </Form>
                  {showSuggetions ? (
                    <SearchSuggetion setShowSuggetions={setShowSuggetions} />
                  ) : null}
                </div>
                <div className="header-profile-sec">
                  <div className="user-dropdown-sec">
                    {!localStorage.getItem("userId") ||
                      !localStorage.getItem("accessToken") ? (
                      <div className="">
                        <Link to="/login">
                          {/* <span>
                           <Image
                             className="header-dropdown-item-icon"
                             src={
                               window.location.origin +
                               "/images/icons/sign-in-icon.svg"
                             }
                           />
                         </span> */}
                          <Button className="subscribe-btn" type="submit">
                            {t("sign_in")}
                          </Button>
                        </Link>
                      </div>
                    ) : (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="desktop-user-img">
                            <Image
                              className="header-user-icon "
                              src={window.location.origin + "/images/user.svg"}
                            />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Link to="/cart">
                            <span>
                              <Image
                                className="header-dropdown-item-icon"
                                src={
                                  window.location.origin +
                                  "/images/icons/shopping-cart.svg"
                                }
                              />
                            </span>
                            {t("view_cart")}
                          </Link>
                          <Link to="/wishlist">
                            <span>
                              <Image
                                className="header-dropdown-item-icon"
                                src={
                                  window.location.origin +
                                  "/images/icons/wishlist.svg"
                                }
                              />
                            </span>
                            {t("wishlist")}
                          </Link>
                          <Link to="/profile">
                            <span>
                              <Image
                                className="header-user-icon"
                                src={
                                  window.location.origin + "/images/user.svg"
                                }
                              />
                            </span>
                            {t("profile")}
                          </Link>
                          <Link to="/logout">
                            <span>
                              <Image
                                className="header-user-icon"
                                src={
                                  window.location.origin +
                                  "/images/logout-img.svg"
                                }
                              />
                            </span>
                            {t("logout")}
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
                <Link to="/cart" className="header-cart-item">
                  <Image
                    className="header-cart-icon"
                    src={
                      window.location.origin + "/images/icons/shopping-cart.svg"
                    }
                  />
                  <span className="add-cart-count">
                    {localStorage.getItem("userId")
                      ? Object.keys(props.profile.data).length > 0
                        ? props.profile.data.cart_count
                        : 0
                      : props.guestCartList.length}
                  </span>
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="new-header-search-mobile">
          <Form onSubmit={searchProduct}>
            <InputGroup className="mb-0">
              <InputGroup.Text id="basic-addon2">
                <Image
                  className="header-search-icon"
                  src={window.location.origin + "/images/icons/search.svg"}
                />
              </InputGroup.Text>
              <Form.Control
                value={search}
                onChange={getSuggetions}
                placeholder={t("search_placeholder")}
                onBlur={() => {
                  setTimeout(() => {
                    setShowSuggetions(false);
                  }, 500);
                }}
              />
            </InputGroup>
          </Form>
          {showSuggetions ? (
            <SearchSuggetion setShowSuggetions={setShowSuggetions} />
          ) : null}
        </div>
      </header>
      <div className="mobile-header-sec">
        <Nav>
          <Link
            to="/"
            className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
          >
            <div class="contact-address-img">
              <svg
                width="683"
                height="683"
                viewBox="0 0 683 683"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_28)">
                  <path d="M674.854 322.479C656.958 304.583 373.307 20.9325 360.187 7.81276C349.776 -2.6039 332.89 -2.6039 322.479 7.81276C308.13 22.1617 25.4114 304.875 7.81249 322.479C-2.60416 332.891 -2.60416 349.776 7.81249 360.193C18.2239 370.604 35.1093 370.604 45.5208 360.193L52.4427 353.271V656C52.4427 670.729 64.3854 682.666 79.1093 682.666H603.557C618.281 682.666 630.223 670.729 630.223 656V353.271L637.14 360.193C647.557 370.604 664.442 370.604 674.854 360.193C685.27 349.776 685.27 332.891 674.854 322.479V322.479ZM419.557 629.333H263.114V472.891H419.557V629.333ZM576.89 629.333H472.89V446.224C472.89 431.495 460.953 419.557 446.224 419.557H236.448C221.719 419.557 209.781 431.495 209.781 446.224V629.333H105.781V299.938L341.333 64.3804L576.89 299.938V629.333Z" />
                </g>
              </svg>
            </div>
          </Link>
          <Link
            to="/shop"
            className={`nav-link ${location.pathname === "/shop" ? "active" : ""
              }`}
          >
            <div class="contact-address-img">
              <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_4)">
                  <path d="M98.6656 271.176C131.547 271.176 160.507 254.958 178.387 230.122C195.617 254.906 224.203 271.176 256.626 271.176C288.965 271.176 317.529 254.99 334.774 230.315C352.549 255.032 381.518 271.176 414.229 271.176C476.974 271.176 524.43 210.372 509.1 149.51L479.186 30.748C474.642 12.7041 458.456 0.102783 439.834 0.102783H71.9397C53.3172 0.102783 37.1367 12.7041 32.5918 30.7491L2.8706 148.804C-5.60898 182.471 5.53674 211.809 25.2211 238.52V471.357C25.2211 493.71 43.2684 511.895 65.6418 511.895H446.282C468.654 511.895 486.71 493.709 486.71 471.357V320.355C486.71 310.56 478.47 302.62 468.666 302.62C458.862 302.62 450.622 310.559 450.622 320.355V471.358C450.622 474.154 449.078 476.902 446.282 476.902H334.703V338.386C334.703 328.591 326.742 320.52 316.938 320.52H194.995C185.192 320.52 177.229 328.59 177.229 338.386V476.902H65.6418C62.8456 476.902 61.3091 474.152 61.3091 471.358V263.729C72.2448 268.595 85.4738 271.176 98.6656 271.176V271.176ZM352.2 35.0972H439.833C442.161 35.0972 444.183 36.9103 444.752 39.1653L474.66 158.045C484.385 196.663 454.284 235.645 414.373 235.645C379.998 235.645 352.2 207.747 352.2 173.381V35.0972ZM196.912 35.0972H317.206V173.381C317.206 206.767 291.086 235.705 256.992 235.705C222.34 235.705 196.912 206.443 196.912 172.814V35.0972ZM213.316 356.608H298.615V476.902H213.316V356.608ZM37.2701 157.46L67.011 39.1675C67.5796 36.9114 69.6126 35.0983 71.9397 35.0983H160.824V172.816C160.824 207.479 132.817 235.681 98.1495 235.705C57.8151 235.68 27.4257 196.552 37.2701 157.46Z" />
                </g>
              </svg>
            </div>
          </Link>
          <Link
            to="/about-us"
            className={`nav-link ${location.pathname === "/about-us" ? "active" : ""
              }`}
          >
            <div class="contact-address-img">
              <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_2)">
                  <path d="M20.005 512C14.908 512 9.889 510.052 6.08 506.359C0.235 500.693 -1.592 492.05 1.459 484.504L46.87 372.171C16.162 332.253 0 285.425 0 236C0 172.625 26.855 113.143 75.62 68.511C123.891 24.331 187.952 0 256 0C324.048 0 388.109 24.331 436.38 68.511C485.145 113.143 512 172.625 512 236C512 281.448 497.96 325.577 471.398 363.615C465.073 372.672 452.606 374.886 443.549 368.562C434.492 362.238 432.278 349.77 438.602 340.713C460.452 309.425 472 273.215 472 236C472 127.925 375.103 40 256 40C136.897 40 40 127.925 40 236C40 279.783 55.577 321.2 85.046 355.773C89.88 361.444 91.161 369.334 88.367 376.243L57.001 453.832L148.346 413.566C153.409 411.335 159.172 411.299 164.262 413.471C193.082 425.766 223.946 432 256 432C292.892 432 329.299 423.413 361.286 407.168C371.136 402.168 383.173 406.096 388.175 415.943C393.176 425.792 389.248 437.83 379.4 442.832C341.828 461.914 299.157 472 256 472C221.52 472 188.165 465.809 156.724 453.587L28.068 510.301C25.474 511.444 22.728 512 20.005 512ZM276 325V217C276 205.954 267.046 197 256 197C244.954 197 236 205.954 236 217V325C236 336.046 244.954 345 256 345C267.046 345 276 336.046 276 325ZM256 128C244.954 128 236 136.954 236 148C236 159.046 244.954 168 256 168C267.046 168 276 159.046 276 148C276 136.954 267.046 128 256 128Z" />
                </g>
              </svg>
            </div>
          </Link>
          <Link
            to="/contact-us"
            className={`nav-link ${location.pathname === "/contact-us" ? "active" : ""
              }`}
          >
            <div class="contact-address-img">
              <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M256 60C198.103 60 151 107.103 151 165C151 200.943 169.126 234.015 199.487 253.467C230.49 273.33 268.547 275.441 303.913 259.17C311.438 255.708 314.733 246.8 311.27 239.274C307.808 231.749 298.901 228.454 291.374 231.916C265.514 243.814 237.92 242.461 215.671 228.207C193.961 214.298 181 190.669 181 165C181 123.645 214.645 90 256 90C297.355 90 331 123.645 331 165C331 173.271 324.271 180 316 180C308.442 180 301.382 174.268 301.002 165.228C301.001 165.152 301 165.076 301 165C301 140.187 280.813 120 256 120C231.187 120 211 140.187 211 165C211 189.813 231.187 210 256 210C267.516 210 278.031 205.647 285.999 198.506C293.966 205.648 304.483 210 316 210C340.813 210 361 189.813 361 165C361 107.103 313.897 60 256 60ZM270.789 167.406C269.631 174.535 263.45 180 256 180C247.729 180 241 173.271 241 165C241 156.729 247.729 150 256 150C263.691 150 270.04 155.82 270.895 163.285C270.671 164.648 270.634 166.035 270.789 167.406Z" />
                <path d="M480.999 196.976C480.995 193.097 479.433 189.22 476.606 186.393L421 130.787V15C421 6.716 414.284 0 406 0H106C97.716 0 91 6.716 91 15V130.787L35.394 186.393C35.342 186.445 35.298 186.503 35.247 186.556C32.436 189.452 31.007 193.265 31.001 196.976C31.001 196.986 31 196.995 31 197.005V467C31 491.845 51.216 512 76 512H436C460.839 512 481 491.793 481 467V197.005C481 196.995 480.999 196.986 480.999 196.976ZM421 173.213L444.787 197L421 220.787V173.213ZM121 137.005C121 137.002 121 136.998 121 136.995V30H391V136.995C391 136.998 391 137.002 391 137.005V250.787L309.787 332H202.213L121 250.787V137.005ZM91 173.213V220.787L67.213 197L91 173.213ZM61 460.787V233.213L174.787 347L61 460.787ZM82.214 482L202.213 362H309.787L429.786 482H82.214ZM451 460.787L337.213 347L451 233.213V460.787Z" />
              </svg>
            </div>
          </Link>
          <Link
            to="/cart"
            className={`nav-link ${location.pathname === "/cart" ? "active" : ""
              }`}
          >
            <div class="contact-address-img">
              <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M473.668 139.109L410.282 303.914H153.733L98.973 30.118H0V71.187H65.305L120.064 344.984H438.488L512 153.853L473.668 139.109Z" />
                <path d="M191.658 358.674C157.689 358.674 130.054 386.308 130.054 420.278C130.054 454.248 157.69 481.882 191.658 481.882C225.627 481.882 253.262 454.248 253.262 420.278C253.262 386.308 225.626 358.674 191.658 358.674ZM191.658 440.813C180.335 440.813 171.123 431.602 171.123 420.278C171.123 408.954 180.335 399.743 191.658 399.743C202.981 399.743 212.193 408.954 212.193 420.278C212.193 431.602 202.981 440.813 191.658 440.813Z" />
                <path d="M369.626 358.674C335.657 358.674 308.022 386.308 308.022 420.278C308.022 454.248 335.658 481.882 369.626 481.882C403.595 481.882 431.23 454.248 431.23 420.278C431.23 386.308 403.594 358.674 369.626 358.674ZM369.626 440.813C358.303 440.813 349.091 431.602 349.091 420.278C349.091 408.954 358.303 399.743 369.626 399.743C380.949 399.743 390.161 408.954 390.161 420.278C390.161 431.602 380.949 440.813 369.626 440.813Z" />
              </svg>
            </div>
            <span className="add-cart-count">
              {localStorage.getItem("userId")
                ? Object.keys(props.profile.data).length > 0
                  ? props.profile.data.cart_count
                  : 0
                : props.guestCartList.length}
            </span>
          </Link>
        </Nav>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  guestCartList: state.carts.guestCartList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(HeaderIndex));
