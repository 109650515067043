import React, { useState } from 'react';

const ThcProgress = ({ percentage, width = "55px", height = "55px", fontsize = "1em" }) => {
  
  const [value, setValue] = useState(Math.ceil(360 * (percentage / 100)));

  const getStyle = () => {

    return {
      background: `radial-gradient(white 60%, transparent 0%),
    conic-gradient(transparent 0deg ${value}deg, gainsboro ${value}deg 360deg),
    conic-gradient(#91c44d 0deg, #8cc63f 60deg, #ebc750 120deg, #f7d373 180deg, #e27c2a 240deg, #ab3726 300deg, #ab3726)`,
      borderRadius: "50%",
      width: width,
      height: height,
      transition: "all 500ms ease-in",
      willChange: "transform",
    }
  }

  return (
    <>
      <div className="progress-circle" style={getStyle()}>
        <div className="text" style={{ fontSize: fontsize }}>THC</div>
        <div className="line l1" />
        <div className="line l2" />
        <div className="line l3" />
      </div>
    </>
    // <>
    //   <svg
    //     width="100%"
    //     height="100%"
    //     viewBox="-10 0 300 300"
    //   >
    //     <linearGradient
    //       id="linearColors1-2f6103d6-b50b-43c6-820c-5fe0cfeb3526"
    //       x1="0"
    //       y1="0"
    //       x2="1"
    //       y2="1"
    //     >
    //       <stop offset="0%" stop-color="#91c44d"></stop>
    //       <stop offset="100%" stop-color="#8cc63f"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="linearColors2-2f6103d6-b50b-43c6-820c-5fe0cfeb3526"
    //       x1="0.5"
    //       y1="0"
    //       x2="0.5"
    //       y2="1"
    //     >
    //       <stop offset="0%" stop-color="#8cc63f"></stop>
    //       <stop offset="100%" stop-color="#ebc750"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="linearColors3-2f6103d6-b50b-43c6-820c-5fe0cfeb3526"
    //       x1="1"
    //       y1="0"
    //       x2="0"
    //       y2="1"
    //     >
    //       <stop offset="0%" stop-color="#ebc750"></stop>
    //       <stop offset="100%" stop-color="#f7d373"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="linearColors4-2f6103d6-b50b-43c6-820c-5fe0cfeb3526"
    //       x1="1"
    //       y1="1"
    //       x2="0"
    //       y2="0"
    //     >
    //       <stop offset="0%" stop-color="#f7d373"></stop>
    //       <stop offset="100%" stop-color="#e27c2a"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="linearColors5-2f6103d6-b50b-43c6-820c-5fe0cfeb3526"
    //       x1="0.5"
    //       y1="1"
    //       x2="0.5"
    //       y2="0"
    //     >
    //       <stop offset="0%" stop-color="#e27c2a"></stop>
    //       <stop offset="100%" stop-color="#ab3726"></stop>
    //     </linearGradient>
    //     <linearGradient
    //       id="linearColors6-2f6103d6-b50b-43c6-820c-5fe0cfeb3526"
    //       x1="0"
    //       y1="1"
    //       x2="1"
    //       y2="0"
    //     >
    //       <stop offset="0%" stop-color="#ab3726"></stop>
    //       <stop offset="100%" stop-color="#ab3726"></stop>
    //     </linearGradient>
    //     <text
    //       class="scale-text"
    //       x="50%"
    //       y="45%"
    //       text-anchor="middle"
    //       stroke="none"
    //       stroke-width="2px"
    //       dy=".3em"
    //     >
    //       THC
    //     </text>

    //     <path
    //       d="M150 10 a120 120 0 0 1 103.9230 60"
    //       fill="none"
    //       stroke={value >= 1 ? "url(#linearColors1-2f6103d6-b50b-43c6-820c-5fe0cfeb3526)" : "#e1e1e1"}
    //       stroke-width="20"
    //       stroke-dasharray="120 10"
    //     ></path>
    //     <path
    //       d="M253.9230 70 a120 120 0 0 1 0 120"
    //       fill="none"
    //       stroke={value >= 2 ? "url(#linearColors2-2f6103d6-b50b-43c6-820c-5fe0cfeb3526)" : "#e1e1e1"}
    //       stroke-width="20"
    //       stroke-dasharray="120 10"
    //     ></path>
    //     <path
    //       d="M253.9230 190 a120 120 0 0 1 -103.9230 60"
    //       fill="none"
    //       stroke={value >= 3 ? "url(#linearColors3-2f6103d6-b50b-43c6-820c-5fe0cfeb3526)" : "#e1e1e1"}
    //       stroke-width="20"
    //       stroke-dasharray="120 10"
    //     ></path>
    //     <path
    //       d="M150 250 a120 120 0 0 1 -103.9230 -60"
    //       fill="none"
    //       stroke={value >= 4 ? "url(#linearColors4-2f6103d6-b50b-43c6-820c-5fe0cfeb3526)" : "#e1e1e1"}
    //       stroke-width="20"
    //       stroke-dasharray="120 10"
    //     ></path>
    //     <path
    //       d="M46.077 190 a120 120 0 0 1 0 -120"
    //       fill="none"
    //       stroke={value >= 5 ? "url(#linearColors5-2f6103d6-b50b-43c6-820c-5fe0cfeb3526)" : "#e1e1e1"}
    //       stroke-width="20"
    //       stroke-dasharray="120 10"
    //     ></path>
    //     <path
    //       d="M46.077 70 a120 120 0 0 1 103.9230 -60"
    //       fill="none"
    //       stroke={value >= 6 ? "url(#linearColors6-2f6103d6-b50b-43c6-820c-5fe0cfeb3526)" : "#e1e1e1"}
    //       stroke-width="20"
    //       stroke-dasharray="120 "
    //     ></path>
    //   </svg>
    // </>
  );
}

export default ThcProgress;