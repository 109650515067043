import React, { useState } from "react";
import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";

const EmptyLayout = (props) => {
  window.scrollTo(0, 0);

  return (
    <>
      <HeaderIndex />
      <div className="main-wrapper">{props.children}</div>
      <FooterIndex />
    </>
  );
};

export default EmptyLayout;
