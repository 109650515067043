import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Image, Button } from "react-bootstrap";
import ProfileSideBar from "./ProfileSideBar";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { twoStepAuthUpdateStart } from "../store/actions/UserAction";

const TwoStepAuthentication = (props) => {
  const t = useTranslation();

  const [initialTwoStep, setInitialTwoStep] = useState(false);
  const [twoStepAuth, setTwoStepAuth] = useState(false);
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      setInitialTwoStep(
        props.profile.data.is_two_step_auth_enabled === 0 ? false : true
      );
      setTwoStepAuth(
        props.profile.data.is_two_step_auth_enabled === 0 ? false : true
      );
    }
  }, [props.profile.data]);
  const changeTwoStepAuth = (values) => {
    props.dispatch(twoStepAuthUpdateStart(values));
  };
  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title two-step-auth-header">
                  <h4>{t("two_step_auth")}</h4>
                  {/* <p>Welcome John!</p> */}
                  {/* <div className="custom-control custom-switch">
                      <input type="checkbox"
                        className="custom-control-input"
                        checked={twoStepAuth}
                        onChange={() => setTwoStepAuth(!twoStepAuth)}
                      />
                      <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
                    </div> */}
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={twoStepAuth}
                    onChange={() => setTwoStepAuth(!twoStepAuth)}
                  />
                </div>
                <div className="two-step-authetication-sec">
                  <p>{t("two_step_auth_text")}
                  </p>
                  {initialTwoStep != twoStepAuth ? (
                    <div className="login-form">
                      <Formik
                        initialValues={{
                          password: "",
                        }}
                        validationSchema={Yup.object().shape({
                          password: Yup.string().required("Required"),
                        })}
                        onSubmit={(values) => changeTwoStepAuth(values)}
                      >
                        {({ errors, touched }) => (
                          <FORM>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("your_password")}</Form.Label>
                                  <div className="input-group d-flex align-items-center">
                                    <Field
                                      type={showPassword ? "text" : "password"}
                                      className="form-control"
                                      placeholder="Enter Your Password"
                                      name="password"
                                    />
                                    <div className="input-group-append">
                                      <button onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                        className="btn password-eye"
                                        type="button"
                                      >{showPassword ? (
                                        <i className="fas fa-eye-slash align-self-center"></i>)
                                        : (
                                          <i className="fas fa-eye align-self-center"></i>
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    component={"div"}
                                    name="password"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <div className="login-btn default-btn-sec">
                              <Button
                                className="default-btn"
                                type="submit"
                                disabled={props.twoStepAuthUpdate.buttonDisable}
                              >
                                {props.twoStepAuthUpdate.loadingButtonContent
                                  ? props.twoStepAuthUpdate.loadingButtonContent
                                  : t("submit")}
                              </Button>
                            </div>
                          </FORM>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                  {/* <ul>
                      <li>
                        <div className="two-step-list">
                          <div className="two-step-count">1</div>
                          <div className="two-step-content">
                            <h5>Download Authenticator App</h5>
                            <p>
                              Install an authenticator app on your mobile device.
                              Recommended options:{" "}
                              <a href="">Google Authenticator,</a>
                              <a href="">Authy,</a>
                              <a href="">Microsoft Authenticator</a>
                            </p>
                            <a href="" className="wishlist-btn">
                              Continue
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="two-step-list">
                          <div className="two-step-count">2</div>
                          <div className="two-step-content">
                            <h5>Add wallet to authenticator app</h5>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="two-step-list">
                          <div className="two-step-count">3</div>
                          <div className="two-step-content">
                            <h5>Verify provided code</h5>
                          </div>
                        </div>
                      </li>
                    </ul> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  twoStepAuthUpdate: state.users.twoStepAuthUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(TwoStepAuthentication));
