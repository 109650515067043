import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";
import {
  deleteUserCardStart,
  setDefaultUserCardStart,
  fetchUserCardListStart,
} from "../store/actions/WalletAction";

const SingleCard = ({ actions = true, ...props }) => {
  const { card, selectedCard, setSelectedCard } = props;
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();

  const setDefaultAccount = (e) => {
    props.dispatch(
      setDefaultUserCardStart({
        user_card_id: card.user_card_id,
      })
    );
    e.stopPropagation();
  };

  const removeAccount = (e) => {
    e.stopPropagation();
    props.dispatch(
      deleteUserCardStart({
        user_card_id: card.user_card_id,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.defaultUserCard.loading &&
      Object.keys(props.defaultUserCard.data).length > 0
    ) {
      props.dispatch(fetchUserCardListStart());
    }
    setSkipRender(false);
  }, [props.defaultUserCard]);

  return (
    <div className="payment-radio-btn">
      <input
        name="plan"
        className="radio"
        type="radio"
        checked={selectedCard === card.user_card_id}
        id="payment"
      />
      <label
        name="plan"
        className="radio"
        type="radio"
        checked={selectedCard === card.user_card_id}
        htmlFor="payment"
        onClick={() => setSelectedCard(card.user_card_id)}
      >
        <p>
          <Image
            src={window.location.origin + "/images/visa.png"}
            type="image/png"
          />
        </p>
        <p>xxxx xxxx xxxx {card.last_four}</p>
        <p>{card.card_holder_name}</p>

        {actions ?
          <>
            {card.is_default == 1 ? (
              <span className="text-success point">
                {t("default")}
              </span>
            ) : props.defaultUserCard.loadingButtonContent &&
              props.defaultUserCard.data.user_card_id === card.user_card_id ? (
              props.defaultUserCard.loadingButtonContent
            ) : (
              <span
                className="text-warning point"
                onClick={setDefaultAccount}
              >
                {t("set_as_default")}
              </span>
            )}

            {props.deleteUserCard.loadingButtonContent &&
              props.deleteUserCard.data.user_card_id === card.user_card_id ? (
              props.deleteUserCard.loadingButtonContent
            ) : (
              <Link to="" className="card-edit-icon" onClick={removeAccount}>
                <span>
                  <Image
                    src={window.location.origin + "/images/delete-1.svg"}
                    type="image/png"
                  />
                </span>
                {t("delete")}
              </Link>
            )}
          </>
          : null
        }
        <div></div>
      </label>
    </div>
  );
};

const mapStateToPros = (state) => ({
  defaultUserCard: state.wallet.defaultUserCard,
  deleteUserCard: state.wallet.deleteUserCard,
  userCardList: state.wallet.userCardList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SingleCard));
