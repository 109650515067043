import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  FETCH_MORE_PRODUCT_LIST_START,
  FETCH_MORE_PRODUCT_REVIEW_LIST_START,
  FETCH_PRODUCT_LIST_START,
  FETCH_PRODUCT_REVIEW_LIST_START,
  FETCH_SINGLE_PRODUCT_START,
  FETCH_PRODUCT_WISHLIST_LIST_START,
  FETCH_MORE_PRODUCT_WISHLIST_LIST_START,
  SAVE_WISHLIST_START,
  FETCH_PRODUCT_SUGGETION_START,
} from "../actions/ActionConstant";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  fetchProductListFailure,
  fetchProductListSuccess,
  fetchProductreviewListFailure,
  fetchProductReviewListSuccess,
  fetchSingleProductFailure,
  fetchSingleProductSuccess,
  fetchProductWishlistListFailure,
  fetchProductWishlistListSuccess,
  saveWishlistsSuccess,
  saveWishlistsFailure,
  fetchProductSuggetionSuccess,
  fetchProductSuggetionFailure,
  forceProductListSuccess,
  forceProductWishlistListSuccess,
} from "../actions/ProductAction";
import { fetchCartListSuccess } from "../actions/CartsAction";


function* fetchProductAPI(action) {
  try {
    const response = yield api.postMethod("products_list", action.data);
    if (response.data.success) {
      yield put(fetchProductListSuccess(response.data.data));
    } else {
      yield put(fetchProductListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchProductListFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchSingleProductAPI(action) {
  try {
    const response = yield api.postMethod("products_view", action.data);
    if (response.data.success) {
      yield put(fetchSingleProductSuccess(response.data.data));
    } else {
      yield put(fetchSingleProductFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSingleProductFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchProductReviewListAPI(action) {
  try {
    const response = yield api.postMethod("product_reviews", action.data);
    if (response.data.success) {
      yield put(fetchProductReviewListSuccess(response.data.data));
    } else {
      yield put(fetchProductreviewListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchProductreviewListFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* fetchProductWishlistListAPI(action) {
  try {
    const response = yield api.postMethod("wishlists_list", action.data);
    if (response.data.success) {
      yield put(fetchProductWishlistListSuccess(response.data.data));
    } else {
      yield put(fetchProductWishlistListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchProductWishlistListFailure(error));
    yield call(getErrorNotificationMessage, error);
  }
}

function* saveWishlistsAPI(action) {
  try {
    const response = yield api.postMethod("wishlists_save", action.data);

    if (response.data.success) {
      yield put(saveWishlistsSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.message);
      // Update Single Product
      const singleProductData = yield select((state) => state.product.singleProduct.data);
      if (Object.keys(singleProductData).length > 0 &&
        singleProductData.product.product_unique_id === action.data.product_unique_id) {
        yield put(fetchSingleProductSuccess({
          ...singleProductData,
          product: {
            ...singleProductData.product,
            is_wishlisted: response.data.data.is_wishlisted,
          }
        }));
      }
      // Update Product List
      const productListData = yield select((state) => state.product.productList.data);
      if (Object.keys(productListData).length > 0) {
        yield put(forceProductListSuccess({
          ...productListData,
          products: productListData.products.map((product) => product.product_unique_id === action.data.product_unique_id
            ? { ...product, is_wishlisted: response.data.data.is_wishlisted }
            : product),
        }));
      }
      // Update Wishlist
      const productWishlistsData = yield select((state) => state.product.productWishlists.data);
      if (Object.keys(productWishlistsData).length > 0) {
        yield put(forceProductWishlistListSuccess({
          ...productWishlistsData,
          products: productWishlistsData.products.filter((product) => product.product_unique_id !== action.data.product_unique_id),
          total_products: (productListData.total_products - 1),
        }));
      }
      // Update Cartlist
      const cartListData = yield select((state) => state.carts.cartList.data);
      if (Object.keys(cartListData).length > 0) {
        yield put(fetchCartListSuccess({
          ...cartListData,
          carts: cartListData.carts.map((cart) => cart.product.product_unique_id === action.data.product_unique_id
            ? { ...cart, product: { ...cart.product, is_wishlisted: response.data.data.is_wishlisted } }
            : cart),
        }));
      }
    } else {
      yield put(saveWishlistsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(saveWishlistsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }

}

function* fetchProductSuggetionAPI(action) {
  try {
    const response = yield api.postMethod("products_list", action.data);

    if (response.data.success) {
      yield put(fetchProductSuggetionSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.message);
    } else {
      yield put(fetchProductSuggetionFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchProductSuggetionFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_PRODUCT_LIST_START, fetchProductAPI),
    yield takeLatest(FETCH_MORE_PRODUCT_LIST_START, fetchProductAPI),
    yield takeLatest(FETCH_SINGLE_PRODUCT_START, fetchSingleProductAPI),
    yield takeLatest(FETCH_PRODUCT_REVIEW_LIST_START, fetchProductReviewListAPI),
    yield takeLatest(FETCH_MORE_PRODUCT_REVIEW_LIST_START, fetchProductReviewListAPI),
    yield takeLatest(FETCH_PRODUCT_WISHLIST_LIST_START, fetchProductWishlistListAPI),
    yield takeLatest(FETCH_MORE_PRODUCT_WISHLIST_LIST_START, fetchProductWishlistListAPI),
    yield takeLatest(SAVE_WISHLIST_START, saveWishlistsAPI),
    yield takeLatest(FETCH_PRODUCT_SUGGETION_START, fetchProductSuggetionAPI),
  ]);
}
