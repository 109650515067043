import React, { useEffect } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  usernameValidationStart,
  userRegisterStart,
} from "../store/actions/UserAction";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import "./Auth.css";
import { useState } from "react";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";

const RegisterIndex = (props) => {
  const navigate = useNavigate();
  const t = useTranslation();
  const [isvalidUserName, setIsValidUserName] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [userName, setUserName] = useState("");
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);

  useEffect(() => {
    if (
      !skipRender &&
      !props.register.loading &&
      Object.keys(props.register.data).length > 0
    ) {
      if (props.register.data.is_email_verified === 0) {
        navigate("/register-verification");
      } else {
        if (localStorage.getItem("product")) {
          let product = localStorage.getItem("product");
          localStorage.removeItem("product");
          navigate(`/product/${product}`);
        } else {
          navigate("/");
        }
      }
    }
    setSkipRender(false);
  }, [props.register]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    email: Yup.string().email(t("invalid_email")).required(t("required")),
    password: Yup.string()
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters"))
      .required(t("required")),
    // username: Yup.string().required(t("required")),
    mobile: Yup.string().matches(
      /^(?=.*[0-9])(?=.{9,})/,
      t("must_contain_9_characters")
    ),
  });

  const handleUsernameValidation = (username) => {
    if (username && username.length > 3) {
      if (username.replace(" ", "") === username) {
        if (username !== userName) {
          setUserName(username);
          setIsValidUserName(true);
          props.dispatch(usernameValidationStart({ username: username }));
          return "";
        }
      } else {
        setIsValidUserName(false);
        return t("no_whitespace");
      }
    } else {
      setIsValidUserName(false);
      return t("must_contain_4_characters");
    }
  };

  const register = (values) => {
    props.dispatch(userRegisterStart(values));
  };

  return (
    <>
      <div className="login-sec register-sec">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={6} lg={5}>
              <div className="login-right-sec">
                <div className="login-img mb-3">
                  <Image
                    src={window.location.origin + "/images/register-img.svg"}
                    type="image/png"
                  />
                </div>
              </div>
            </Col>
            <Col xl={6} md={12} lg={7}>
              <div className="login-form">
                <h3>{t("create_account")}</h3>
                <Formik
                  initialValues={{
                    name: "",
                    username: "",
                    mobile: "",
                    email: "",
                    password: "",
                    dob: localStorage.getItem("userDOB"),
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => register(values)}
                >
                  {({ errors, touched }) => (
                    <FORM>
                      <div className="required-content">
                        <p>{t("required_fields")}*</p>
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("name")}*</Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder={t("name_placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="name"
                          className="text-danger"
                        />
                      </Form.Group>
                      {/* <Form.Group className="mb-3">
                        <Form.Label>{t("username")}</Form.Label>
                        <Field
                          type="text"
                          name="username"
                          placeholder={t("username_placeholder")}
                          className="form-control"
                          validate={handleUsernameValidation}
                        />
                        {errors.username ? (
                          <div className="text-danger">{errors.username}</div>
                        ) : props.usernameValidation.loadingButtonContent ? (
                          <img
                            src={
                              window.location.origin +
                              "/images/username-loading.gif"
                            }
                            width="15"
                            height="15"
                          />
                        ) : props.usernameValidation.data.success === true ? (
                          <div className="text-success">
                            {props.usernameValidation.data.message}
                          </div>
                        ) : (
                          <div className="text-danger">
                            {props.usernameValidation.error}
                          </div>
                        )}
                      </Form.Group> */}
                      <Form.Group className="mb-3">
                        <Form.Label>{t("mobile_no")}</Form.Label>
                        <Field
                          type="number"
                          className="form-control"
                          name="mobile"
                          placeholder={t("mobile_placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="mobile"
                          className="text-danger"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t("email")}*</Form.Label>
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder={t("email_placeholder")}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="text-danger"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>{t("password")}*</Form.Label>

                        <div className="input-group d-flex align-items-center">
                          <Field
                            type={loginPasswordVisible ? "text" : "password"}
                            name="password"
                            placeholder={t("password_placeholder")}
                            className="form-control"
                          />
                          <div className="input-group-append">
                            <button
                              onClick={() =>
                                setLoginPasswordVisible(!loginPasswordVisible)
                              }
                              className="btn password-eye"
                              type="button"
                            >
                              {loginPasswordVisible ? (
                                <i className="fas fa-eye-slash align-self-center"></i>
                              ) : (
                                <i className="fas fa-eye align-self-center"></i>
                              )}
                            </button>
                          </div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="password"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <div className="login-btn default-btn-sec reset-btn">
                        <Button
                          className="default-btn"
                          type="submit"
                          disabled={props.register.buttonDisable}
                        >
                          {props.register.loadingButtonContent
                            ? props.register.loadingButtonContent
                            : t("submit")}
                        </Button>
                        <span>{t("or")}</span>

                        <Link to="/shop">{t("return_to_store")}</Link>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  register: state.users.register,
  usernameValidation: state.users.usernameValidation,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(RegisterIndex));
