import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./NewCheckOut.css";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { fetchSingleDeliveryAddressStart } from "../store/actions/DeliveryAction";
import SomethingWrong from "../Helper/SomethingWrong";
import Skeleton from "react-loading-skeleton";
import { fetchCartListStart } from "../store/actions/CartsAction";
import CustomLazyLoad from "../Helper/CustomLazyLoad";

const OrderSummary = (props) => {
  const navigate = useNavigate();

  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);
  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    console.log(showContent);
    if (localStorage.getItem("addessUniqueId")) {
      props.dispatch(fetchSingleDeliveryAddressStart({
        delivery_address_unique_id: localStorage.getItem("addessUniqueId")
      }));
      props.dispatch(fetchCartListStart());
    } else {
      navigate("/checkout-address");
    }
  }, []);

  useEffect(() => {
    if (!skipRender &&
      (!props.singleDeliveryAddress.loading && (props.singleDeliveryAddress.error !== false || !props.singleDeliveryAddress.data.delivery_address)) ||
      (!props.cartList.loading && (props.cartList.error !== false || !props.cartList.data.carts || !props.cartList.data.user_cart_amount ||
        !props.cartList.data.availability || !props.cartList.data.availability.is_available))) {
      setShowContent(false);
    }
    setSkipRender(false);
  }, [props.singleDeliveryAddress, props.cartList]);

  const changeAddress = e => {
    e.preventDefault();
    localStorage.removeItem("addessUniqueId");
    navigate("/checkout-address");
  }


  return (
    <>
      <div className="newcheckout-sec">
        {showContent
          ? <Container>
            <Row>
              <Col xl={8} md={12} className="resp-mrg-btm-md">
                <div className="newcheckout-header-sec">
                  <ul className="list-unstyled new-checkout-progress-bar">
                    <li className="step1">
                      <div className="newcheckout-flex active">
                        <div className="count-no">
                          1
                        </div>
                        <h6>{t("address")}</h6>
                      </div>
                    </li>
                    <li className="step2">
                      <div className="newcheckout-flex active">
                        <div className="count-no">
                          2
                        </div>
                        <h6>{t("order_summary")}</h6>
                      </div>
                    </li>
                    <li className="step3">
                      <div className="newcheckout-flex">
                        <div className="count-no">
                          3
                        </div>
                        <h6>{t("payment")}</h6>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="newcheckout-body-wrapper">
                  <div className="step-2">
                    <div className="product-delivery-address-box">
                      {props.singleDeliveryAddress.loading
                        ? <Skeleton height={200} />
                        : props.singleDeliveryAddress.data.delivery_address
                          ? <div className="saved-address-box">
                            <div className="address-details">
                              <h3>Deliver to</h3>
                              <h4>{props.singleDeliveryAddress.data.delivery_address.name}</h4>
                              <p className="address-block">
                                {props.singleDeliveryAddress.data.delivery_address.address}{"\n"}
                                {props.singleDeliveryAddress.data.delivery_address.city}{" "}
                                {props.singleDeliveryAddress.data.delivery_address.state}{" "}
                                {props.singleDeliveryAddress.data.delivery_address.pincode}{"\n"}
                                {props.singleDeliveryAddress.data.delivery_address.landmark}{"\n"}
                                {props.singleDeliveryAddress.data.delivery_address.contact_number}
                              </p>
                            </div>
                            <div className="address-btn">
                              <Button
                                className="address-button"
                                onClick={changeAddress}
                              >
                                {t("change")}
                              </Button>
                            </div>
                          </div>
                          : null
                      }
                    </div>
                    {props.cartList.loading
                      ? <Skeleton count={3} height={200} />
                      : props.cartList.data.carts.filter(cart => cart.product.stock_status !== 0).map((cart, i) =>
                        <div className={`product-delivery-status-box
                          ${cart.product.stock_status === 0 ? "disable-cart-product" : ""}`} key={i}>
                          <div className="product-delivery-status-card">
                            <div className="product-delivery-status-left-sec">
                              <div className="checkout-product-img">
                                <CustomLazyLoad
                                  src={cart.product.file}
                                  placeholderSrc={window.location.origin + "/images/loading.svg"}
                                  classes="cart-list-img"
                                />
                              </div>
                              <div className="checkout-product-content">
                                <h4>{cart.product.name}</h4>
                                <p>
                                  {t("category")}<span>{cart.product.category_name}</span>
                                </p>
                                <p>
                                  {t("vol")}<span>{cart.product.gross_weight_formatted}</span>
                                </p>
                                <p>
                                  {t("qty")}<span>{cart.product.stock_status === 0 ? cart.product.stock_availablity_formatted : cart.quantity}</span>
                                </p>

                              </div>
                            </div>
                            <div className="product-delivery-status-right-sec">
                              <div className="checkout-product-content">
                                <h4>{cart.product.selling_price_formatted}</h4>
                                <h5>
                                  {cart.product.discount_tag} <span>{cart.product.original_price_formatted}</span>
                                </h5>
                              </div>
                            </div>
                          </div>
                          {/* <div className="product-delivery-status-bar">
                        Delivery by Thu Sep 8
                      </div> */}
                        </div>
                      )
                    }
                    <div className="product-confirm-continue-box">
                      <h4>{t("confirm_your_order_text")}</h4>
                      <div className="address-btn">
                        {!props.cartList.loading &&
                          props.cartList.data.availability &&
                          props.cartList.data.availability.is_available
                          ? <Link to="/checkout-payment" className="address-button">
                            {t("continue")}
                          </Link>
                          : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} md={12}>
                <div className="checkout-box">
                  <div className="cart-box">
                    <div className="cart-header-sec">
                      <h4>{t("price_details")}</h4>
                    </div>
                    {props.cartList.loading
                      ? <Skeleton height={200} />
                      : <div className="cart-body-sec">
                        <div className="cart-summary-info">
                          <div className="cart-summary-header">
                            <p>
                              {t("cart_total")} <span>{props.cartList.data.user_cart_amount.cart_total_amount}</span>
                            </p>
                            <p>
                              {t("discount")} <span>-{props.cartList.data.user_cart_amount.cart_discount_amount}</span>
                            </p>
                          </div>
                          <div className="cart-summary-total-amount">
                            <h5>
                              {t("order_total")} <span>{props.cartList.data.user_cart_amount.cart_checkout_amount}</span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          : <div className="text-center">
            <SomethingWrong />
            <Button onClick={() => navigate("/")}>{t("go_home")}</Button>
          </div>
        }
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  cartList: state.carts.cartList,
  singleDeliveryAddress: state.delivery.singleDeliveryAddress,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(OrderSummary));
