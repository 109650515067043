import {
  FETCH_BANNERS_LIST_START,
  FETCH_BANNERS_LIST_SUCCESS,
  FETCH_BANNERS_LIST_FAILURE,
  FETCH_CATEGORIES_LIST_START,
  FETCH_CATEGORIES_LIST_SUCCESS,
  FETCH_CATEGORIES_LIST_FAILURE,
  FETCH_TOP_PRODUCTS_LIST_START,
  FETCH_TOP_PRODUCTS_LIST_SUCCESS,
  FETCH_TOP_PRODUCTS_LIST_FAILURE,
  FETCH_FAQS_LIST_START,
  FETCH_FAQS_LIST_SUCCESS,
  FETCH_FAQS_LIST_FAILURE,
  CONTACT_SUBMIT_START,
  CONTACT_SUBMIT_SUCCESS,
  CONTACT_SUBMIT_FAILURE,
  CUSTOMER_REVIEWS_LIST_START,
  CUSTOMER_REVIEWS_LIST_SUCCESS,
  CUSTOMER_REVIEWS_LIST_FAILURE
} from "../actions/ActionConstant";

const initialState = {
  bannerList: {
    data: {},
    loading: true,
    error: false,
  },
  categoriesList: {
    data: {},
    loading: true,
    error: false,
  },
  topProductList: {
    data: {},
    loading: true,
    error: false,
  },
  faqsList: {
    data: {},
    loading: true,
    error: false,
  },
  contactSubmit: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  customerReviewsList:{
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const homePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BANNERS_LIST_START:
      return {
        ...state,
        bannerList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_BANNERS_LIST_SUCCESS:
      return {
        ...state,
        bannerList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_BANNERS_LIST_FAILURE:
      return {
        ...state,
        bannerList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_CATEGORIES_LIST_START:
      return {
        ...state,
        categoriesList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        categoriesList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_CATEGORIES_LIST_FAILURE:
      return {
        ...state,
        categoriesList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_TOP_PRODUCTS_LIST_START:
      return {
        ...state,
        topProductList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_TOP_PRODUCTS_LIST_SUCCESS:
      return {
        ...state,
        topProductList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_TOP_PRODUCTS_LIST_FAILURE:
      return {
        ...state,
        topProductList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case FETCH_FAQS_LIST_START:
      return {
        ...state,
        faqsList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_FAQS_LIST_SUCCESS:
      return {
        ...state,
        faqsList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_FAQS_LIST_FAILURE:
      return {
        ...state,
        faqsList: {
          data: {},
          loading: false,
          error: action.error,
        },
      };
    case CONTACT_SUBMIT_START:
      return {
        ...state,
        contactSubmit: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case CONTACT_SUBMIT_SUCCESS:
      return {
        ...state,
        contactSubmit: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CONTACT_SUBMIT_FAILURE:
      return {
        ...state,
        contactSubmit: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
      case CUSTOMER_REVIEWS_LIST_START:
        return {
          ...state,
          customerReviewsList: {
            data: {},
            loading: true,
            error: false,
            buttonDisable: true,
            loadingButtonContent: "Loading",
          },
        };
      case CUSTOMER_REVIEWS_LIST_SUCCESS:
        return {
          ...state,
          customerReviewsList: {
            data: action.data,
            loading: false,
            error: false,
            buttonDisable: false,
            loadingButtonContent: null,
          },
        };
      case CUSTOMER_REVIEWS_LIST_FAILURE:
        return {
          ...state,
          customerReviewsList: {
            data: {},
            loading: false,
            error: action.error,
            buttonDisable: false,
            loadingButtonContent: null,
          },
        };

    default:
      return state;
  }
};

export default homePageReducer;
