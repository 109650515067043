import React, { useState } from "react";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Landing.css";
import configuration from "react-global-configuration";

const LandingAboutIndex = (props) => {
  const t = useTranslation();

  return (
    <>
      <div className="landing-about-sec">
        <div className="landing-about-box">
          <div className="landing-about-card-left">
            <div className="section-title-sec">
              <h5 className="title-light">{t("about_us")}</h5>
              <h2 className="title-bold">
                <span>{t("about_us_text")}</span>
              </h2>
            </div>
            <div className="landing-about-info">
              <p>{t("about_content_1",{site_name: `${configuration.get("configData.site_name")}`, name: `${configuration.get("configData.site_name")}`})}</p>
              <p>{t("about_content_2",{site_name: `${configuration.get("configData.site_name")}`})}</p>
              <p>{t("about_content_3",{site_name: `${configuration.get("configData.site_name")}`})}</p>
            </div>
          </div>
          <div className="landing-about-card-right">
            <div className="landing-about-card-right-img-sec">
              <Image
                className="landing-about-card-right-img"
                src={window.location.origin + "/images/about-img-1.png"}
                type="image/png"
                alt={`${configuration.get("configData.site_name")} Ecommerce Site`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation(LandingAboutIndex);
