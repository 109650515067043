import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { getErrorNotificationMessage } from '../Helper/ToastNotification';
import { fetchUserCardListStart, fetchUserWalletDetailsStart, walletPaymentByStripeStart } from '../store/actions/WalletAction';
import { connect } from 'react-redux';
import SingleCard from '../Profile/SingleCard';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../Helper/NoDataFound';
import SomethingWrong from '../Helper/SomethingWrong';
import { useNavigate } from 'react-router-dom';
import { withTranslation, useTranslation } from "react-multi-lang";

const AddMoneyModal = (props) => {
  const navigate = useNavigate();

  const [amount, setAmount] = useState("1");
  const [skipFirst, setSkipFirst] = useState(true);
  const t = useTranslation();
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    props.dispatch(fetchUserCardListStart());
  }, []);

  useEffect(() => {
    if (
      !skipFirst &&
      !props.walletPaymentByStripe.loading &&
      Object.keys(props.walletPaymentByStripe.data).length > 0
    ) {
      props.dispatch(fetchUserWalletDetailsStart());
      props.closeAddMoneyModal();
    }
  }, [props.walletPaymentByStripe]);

  useEffect(() => {
    if (!skipFirst && !props.userCardList.loading && Object.keys(props.userCardList.data).length > 0) {
      if (props.userCardList.data.user_cards && props.userCardList.data.user_cards.length > 0) {
        props.userCardList.data.user_cards.map((card, i) => {
          if (card.is_default === 1)
            setSelectedCard(card.user_card_id);
        })
      } else {
        setSelectedCard(null);
      }
    }
    setSkipFirst(false);
  }, [props.userCardList]);

  const addMoney = e => {
    e.preventDefault();
    if (amount && selectedCard) {
      props.dispatch(
        walletPaymentByStripeStart({
          amount: amount,
          user_card_id: selectedCard,
        })
      );
    } else if (!amount) {
      props.dispatch(getErrorNotificationMessage(t("please_enter_the_amount")));
    } else if (!selectedCard) {
      props.dispatch(
        getErrorNotificationMessage(t("please_add_or_select_a_card"))
      );
    }
  };

  return (
    <>
      <Modal
        className="modal-dialog-center write-review-modal"
        size="lg"
        centered
        show={props.addMoney}
        onHide={props.closeAddMoneyModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("add_money")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="write-review-form" onSubmit={addMoney}>
            <div className="add-money-sec">
              <div className="add-money-input">
                <Form.Group className="">
                  <Form.Control
                    type="number"
                    className="form-control"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    min="0"
                  />
                </Form.Group>
                <div className="currency">$</div>
              </div>
              <div className="add-money-btn">
                <Button
                  className="wishlist-btn"
                  type="submit"
                  disabled={props.walletPaymentByStripe.buttonDisable}
                >
                  {props.walletPaymentByStripe.loadingButtonContent
                    ? props.walletPaymentByStripe.loadingButtonContent
                    : "Submit"}
                </Button>
              </div>
            </div>

            <div className="payment-radio-box">
              {props.userCardList.loading ?
                <Skeleton
                  count={2}
                  height={100}
                />
                : props.userCardList.data.user_cards ?
                  props.userCardList.data.user_cards.length > 0 ?
                    props.userCardList.data.user_cards.map((card, i) =>
                      <SingleCard
                        card={card}
                        key={i}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                        actions={false}
                      />
                    )
                    : <div className="payment-radio-btn">
                      <label
                        name="plan"
                        className="radio"
                        htmlFor="payment"
                        onClick={() => navigate("/wallet-add-money")}
                      >
                        <p>{t("add_new_card")}</p>
                      </label>
                    </div>
                  : <SomethingWrong />
              }
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );

}

const mapStateToPros = (state) => ({
  walletPaymentByStripe: state.wallet.walletPaymentByStripe,
  userCardList: state.wallet.userCardList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(AddMoneyModal);