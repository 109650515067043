import React, { useState } from "react";
import { Row, Col, Container, Form, Image, Button } from "react-bootstrap";
import { updateUserDetailsStart } from "../store/actions/UserAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import ProfileSideBar from "./ProfileSideBar";

const MobileProfile = (props) => {
  const [imgPreviewAdd, setImgPreviewAdd] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const t = useTranslation();

  const imgPreview = (e) => {
    setImgPreviewAdd(URL.createObjectURL(e.target.files[0]));
    setProfileImg(e.target.files[0]);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("invalid_email")).required(t("required")),
    name: Yup.string().required(t("required")),
    mobile: Yup.string().matches(
      /^(?=.*[0-9])(?=.{9,})/,
      t("must_contain_9_characters")
    ),
  });

  const handleSubmit = (values) => {
    let newValues = values;
    if (profileImg) {
      newValues = {
        ...values,
        picture: profileImg,
      };
    }
    props.dispatch(updateUserDetailsStart(newValues));
  };

  return (
    <div className="profile-sec">
      <Row className="g-0">
        <Col md={12} xl={9} lg={8}>
          <div className="new-mobile-section">
            <ProfileSideBar />
          </div>
        </Col>
        <Col md={12} xl={9} lg={8}>
          <div className="profile-details-sec new-mobile-profile">
            <div className="profile-details-title">
              <h4>{t("personal_details")}</h4>
              <p>
                {t("welcome")} {props.profile.data.name}
              </p>
            </div>
            {props.profile.loading ? (
              "loading"
            ) : Object.keys(props.profile.data).length > 0 ? (
              <div className="login-form">
                <Formik
                  initialValues={{
                    name: props.profile.data.name,
                    mobile: props.profile.data.mobile,
                    email: props.profile.data.email,
                    // username: props.profile.data.username,
                  }}
                  onSubmit={(values) => handleSubmit(values)}
                  validationSchema={validationSchema}
                >
                  <FORM>
                    <div className="profile-img">
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>
                          <Image
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "50%",
                              objectFit: "cover",
                              position: "center",
                            }}
                            src={
                              imgPreviewAdd
                                ? imgPreviewAdd
                                : props.profile.data.picture
                            }
                            type="image/png"
                          />
                          <div className="input-edit-img">
                            <Image
                              src={window.location.origin + "/images/edit.svg"}
                              type="image/png"
                            />
                          </div>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          className="image-input"
                          onChange={(e) => imgPreview(e)}
                        />
                      </Form.Group>
                    </div>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("name")}*</Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder={t("name_placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>{t("email")}*</Form.Label>
                          <Field
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder={t("email_placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("mobile_no")}</Form.Label>
                          <Field
                            type="number"
                            className="form-control"
                            name="mobile"
                            placeholder={t("mobile_placeholder")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="mobile"
                            className="text-danger"
                          />
                        </Form.Group>
                      </Col>
                      <div className="login-btn default-btn-sec">
                        <Button
                          className="default-btn"
                          type="submit"
                          disabled={props.profileInputData.buttonDisable}
                        >
                          {props.profileInputData.loadingButtonContent
                            ? props.profileInputData.loadingButtonContent
                            : t("save")}
                        </Button>
                      </div>
                    </Row>
                  </FORM>
                </Formik>
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  profileInputData: state.users.profileInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(MobileProfile));
