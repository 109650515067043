import { apiConstants } from "../Constant/constants";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Tab,
  Nav,
  FormControl,
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  saveDeliveryAddressesStart,
  fetchDeliveryAddressesListStart,
  getCountriesStart,
  getStatesStart,
} from "../store/actions/DeliveryAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Select from "react-select";

const AddressSubmitForm = ({ isModal = false, ...props }) => {
  const t = useTranslation();
  const [address, setAddress] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    contact_number: Yup.string().required(t("required")).min(5, t("min_10")),
    city: Yup.string().required(t("required")),
    state: Yup.object().required(t("required")),
    country: Yup.object().required(t("required")),
    pincode: Yup.string().required(t("required")),
    landmark: Yup.string().required(t("required")),
    address: Yup.string().required(t("required")),
  });

  const handleSubmit = (values) => {
    props.dispatch(
      saveDeliveryAddressesStart(
        props.editAddress.delivery_address_id
          ? {
            ...values,
            country: values.country.label,
            state: values.state.value,
            delivery_address_id: props.editAddress.delivery_address_id,
          }
          : {
            ...values,
            country: values.country.label,
            state: values.state.value,
          }
      )
    );
  };

  useEffect(() => {
    props.dispatch(getCountriesStart());
  }, []);

  const onCountryChange = (value) => {
    props.dispatch(getStatesStart({
      country_code: value.value,
    }));
  }

  useEffect(() => {
    if (
      !skipRender &&
      !props.deliveryAddressesSave.loading &&
      Object.keys(props.deliveryAddressesSave.data).length > 0
    ) {
      if (!isModal) {
        props.setActiveTab("saved_billing");
        props.dispatch(fetchDeliveryAddressesListStart());
      }
    }
    setSkipRender(false);
  }, [props.deliveryAddressesSave]);

  return (
    <div className="new-address-sec mt-4">
      <div className="login-form">
        <Formik
          initialValues={{
            name: props.editAddress.name ? props.editAddress.name : "",
            contact_number: props.editAddress.contact_number
              ? props.editAddress.contact_number
              : "",
            city: props.editAddress.city ? props.editAddress.city : "",
            state: props.editAddress.state ? props.editAddress.state : "",
            country: props.editAddress.country ? props.editAddress.country : "",
            pincode: props.editAddress.pincode ? props.editAddress.pincode : "",
            landmark: props.editAddress.landmark
              ? props.editAddress.landmark
              : "",
            address: props.editAddress.address ? props.editAddress.address : "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue
          }) =>
            <FORM>
              <Row>
                <Col md={12} xl={6} lg={6}>
                  <Form.Group
                    className="mb-4 select-address-type"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{t("name")}*</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder={t("address_choose")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="name"
                      className="text-danger"
                    />
                    <div className="select-address-type-btn">
                      <Button
                        className="text-btn"
                        type="button"
                        onClick={e => setFieldValue("name", "Home")}
                      >
                        {t("home")}
                      </Button>
                      <Button
                        className="text-btn"
                        type="button"
                        onClick={() => setFieldValue("name", "Work")}
                      >
                        {t("work")}
                      </Button>
                      <Button
                        className="text-btn"
                        type="button"
                        onClick={() => setFieldValue("name", "Others")}
                      >
                        {t("others")}
                      </Button>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={12} xl={6} lg={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{t("contact_number")}*</Form.Label>
                    <Field
                      type="number"
                      className="form-control"
                      name="contact_number"
                      placeholder={t("contact_placeholder")}
                      min="0"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="contact_number"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{t("country")}*</Form.Label>
                    {/* <Field
                    type="text"
                    className="form-control"
                    name="country"
                    placeholder={t("country_placeholder")}
                    min="0"

                  /> */}
                    <Select
                      options={props.countries.data.countries ? props.countries.data.countries : []}
                      value={values.country}
                      onChange={value => {
                        onCountryChange(value);
                        setFieldValue("country", value);
                      }}
                      placeholder={t("country_placeholder")}
                      noOptionsMessage={() => t("no_countries")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="country"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{t("state")}*</Form.Label>
                    {/* <Field
                      type="text"
                      className="form-control"
                      name="state"
                      placeholder={t("state_placeholder")}
                      min="0"
                    /> */}
                    <Select
                      options={props.states.data.states ? props.states.data.states : []}
                      value={values.state}
                      onChange={value => setFieldValue("state", value)}
                      placeholder={t("state_placeholder")}
                      noOptionsMessage={() => t("no_states")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="state"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{t("city")}*</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="city"
                      placeholder={t("city_placeholder")}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="city"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{t("pincode")}*</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="pincode"
                      placeholder={t("pincode_placeholder")}
                      min="0"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="pincode"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>{t("landmark")}*</Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      name="landmark"
                      placeholder={t("landmark_placeholder")}
                      min="0"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="landmark"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>{t("address_area")}*</Form.Label>
                    <Field
                      as="textarea"
                      className="form-control"
                      name="address"
                      placeholder={t("address_placeholder")}
                      min="0"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="address"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>



              </Row>
              <Form.Group
                className="mb-3 form-check-custom"
                controlId="formBasicCheckbox"
              >
                <Form.Check type="checkbox" label={t("use_as_default_add")} />
              </Form.Group>
              <div className="default-btn-sec">
                <Button
                  type="submit"
                  className="subscribe-btn"
                  disabled={props.deliveryAddressesSave.buttonDisable}
                >
                  {props.deliveryAddressesSave.loadingButtonContent
                    ? props.deliveryAddressesSave.loadingButtonContent
                    : t("save")}
                </Button>
              </div>
            </FORM>
          }
        </Formik>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  deliveryAddressesSave: state.delivery.deliveryAddressesSave,
  deliveryAddressesList: state.delivery.deliveryAddressesList,
  countries: state.delivery.countries,
  states: state.delivery.states,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(AddressSubmitForm));
