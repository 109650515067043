import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Link } from "react-router-dom";
import PaymentSection from "./PaymentSection";
import ProfileSideBar from "./ProfileSideBar";

const PaymentMethod = (props) => {
  const t = useTranslation();

  const [selectedCard, setSelectedCard] = useState(null);

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title">
                  <h4>{t("payment_method")}</h4>
                </div>
                <div className="address-tab">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      {/* <Col md={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Cards</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Others</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col> */}
                      <Col md={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <PaymentSection
                              selectedCard={selectedCard}
                              setSelectedCard={setSelectedCard}
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second"></Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default (withTranslation(PaymentMethod));
