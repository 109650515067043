import "./App.css";
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./components/store";
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import configuration from "react-global-configuration";
import { useEffect, useState } from "react";
import { apiConstants } from "./components/Constant/constants";
import { BrowserRouter } from 'react-router-dom';
import AgeVerificationIndex from "./components/AgeVerification/AgeVerificationIndex";
import { WagmiConfig, createClient, } from 'wagmi';
import { Buffer } from "buffer";
import { ConnectKitProvider, getDefaultClient } from "connectkit";


const alchemyId = process.env.ALCHEMY_ID; 

const createWalletClient = () => {
  const avalancheChain = {
    id: Number(configuration.get("configData.chain_id")),
    name: configuration.get("configData.chain_name"),
    nativeCurrency: {
      decimals: configuration.get("configData.native_currency_decimals"),
      name: configuration.get("configData.native_currency_name"),
      symbol: configuration.get("configData.native_currency_symbol"),
    },
    rpcUrls: {
      default: configuration.get("configData.rpc_url"),
    },
    blockExplorers: {
      default: { name: "Binance Test", url: configuration.get("configData.block_explorer_url") },
      // snowtrace: { name: "SnowTrace", url: "https://testnet.bscscan.com" },
    },
    testnet: true,
  };

  const chains = [avalancheChain];
  if (!window.Buffer) window.Buffer = Buffer;
  const client = createClient({
    ...getDefaultClient({
      appName: "Your App Name",
      alchemyId,
      chains,
    }),
  });
  return client;
}


function App() {

  const [client, setClient] = useState();

  const [ageVerification, setAgeVerification] = useState({
    isAgeVerified: localStorage.getItem("isAgeVerified"),
    userAge: localStorage.getItem("userAge"),
  });

  const [validateBtn, setValidateBtn] = useState({
    loading: false,
    buttonDisable: false,
    content: "",
  });

  const [isUserEligible, setIsUserEligible] = useState(true);

  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setClient(createWalletClient());
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  const handleSubmit = (values) => {
    setIsUserEligible(true);
    setValidateBtn({
      ...validateBtn,
      loading: true,
      buttonDisable: true,
      content: "Validating...",
    });
    let bYear = values.year;
    let bDay = values.day;
    let bMonth = values.month;
    let userBirthday = bYear + '-' + bMonth + '-' + bDay
    const date = new Date();
    let current_Year = date.getFullYear();
    let current_Age = current_Year - bYear

    if (current_Age > 18) {
      localStorage.setItem("isAgeVerified", true)
      localStorage.setItem("userAge", current_Age)
      localStorage.setItem("userDOB", userBirthday)
      setAgeVerification({
        ...ageVerification,
        isAgeVerified: true,
        userAge: current_Age,
      });
    } else {
      setIsUserEligible(false);
    }
    setValidateBtn({
      ...validateBtn,
      loading: false,
      buttonDisable: false,
      content: "",
    });
  };

  return (
    <Provider store={store}>
      {!configLoading && (
        <WagmiConfig client={client}>
          <ConnectKitProvider>
            {ageVerification.isAgeVerified && ageVerification.userAge > 18 ? (
              <Base />
            ) : (
              <BrowserRouter>
                <AgeVerificationIndex
                  validateBtn={validateBtn}
                  handleSubmit={handleSubmit}
                  isUserEligible={isUserEligible}
                />
              </BrowserRouter>
            )
            }
          </ConnectKitProvider>
        </WagmiConfig>
      )}
    </Provider>
  );
}

export default App;
