import React from "react";
import { Container } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import "./StaticPage.css";

const PrivacyPolicy = () => {

  const t = useTranslation();



  return (
    <>
      <div className="static-page-sec">
        <div className="static-page-bg">
          <div className="static-banner-content">
            <h2>{t("privacy_policy")}</h2>
          </div>
        </div>
        <div className="static-page-content">
          <Container>
            <h3>{t("who_we_are")}</h3>
            <p>{t("our_website_is_poshcoin")}</p>
            <h3>{t("what_data_and_why_we_colect")}</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit,
              aperiam corporis praesentium aut sed id in ab, nulla ad cum iure
              alias, numquam deleniti unde vero molestiae minus nemo
              illo?numquam deleniti unde vero molestiae minus nemo illo?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
              obcaecati eveniet iste similique, placeat alias eligendi enim eius
              reprehenderit a officiis et voluptate qui saepe accusantium sit
              eum vitae deserunt.Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. Impedit, aperiam corporis praesentium aut sed id
              in ab, nulla ad cum iure alias, numquam deleniti unde vero
              molestiae minus nemo illo?
            </p>
            <h3>{t("what_data_and_why_we_colect")}</h3>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Impedit,
              aperiam corporis praesentium aut sed id in ab, nulla ad cum iure
              alias, numquam deleniti unde vero molestiae minus nemo
              illo?numquam deleniti unde vero molestiae minus nemo illo?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
              obcaecati eveniet iste similique, placeat alias eligendi enim eius
              reprehenderit a officiis et voluptate qui saepe accusantium sit
              eum vitae deserunt.Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. Impedit, aperiam corporis praesentium aut sed id
              in ab, nulla ad cum iure alias, numquam deleniti unde vero
              molestiae minus nemo illo?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
              obcaecati eveniet iste similique, placeat alias eligendi enim eius
              reprehenderit a officiis et voluptate qui saepe accusantium sit
              eum vitae deserunt.Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. Impedit, aperiam corporis praesentium aut sed id
              in ab, nulla ad cum iure alias, numquam deleniti unde vero
              molestiae minus nemo illo?Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Neque obcaecati eveniet iste similique, placeat
              alias eligendi enim eius reprehenderit a officiis et voluptate qui
              saepe accusantium sit eum vitae deserunt.Lorem ipsum, dolor sit
              amet consectetur adipisicing elit. Impedit, aperiam corporis
              praesentium aut sed id in ab, nulla ad cum iure alias, numquam
              deleniti unde vero molestiae minus nemo illo?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
              obcaecati eveniet iste similique, placeat alias eligendi enim eius
              reprehenderit a officiis et voluptate qui saepe accusantium sit
              eum vitae deserunt.Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. Impedit, aperiam corporis praesentium aut sed id
              in ab, nulla ad cum iure alias, numquam deleniti unde vero
              molestiae minus nemo illo?Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Neque obcaecati eveniet iste similique, placeat
              alias eligendi enim eius reprehenderit a officiis et voluptate qui
              saepe accusantium sit eum vitae deserunt.Lorem ipsum, dolor sit
              amet consectetur adipisicing elit. Impedit, aperiam corporis
              praesentium aut sed id in ab, nulla ad cum iure alias, numquam
              deleniti unde vero molestiae minus nemo illo?
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
              obcaecati eveniet iste similique, placeat alias eligendi enim eius
              reprehenderit a officiis et voluptate qui saepe accusantium sit
              eum vitae deserunt.Lorem ipsum, dolor sit amet consectetur
              adipisicing elit. Impedit, aperiam corporis praesentium aut sed id
              in ab, nulla ad cum iure alias, numquam deleniti unde vero
              molestiae minus nemo illo?Lorem ipsum dolor sit amet consectetur
              adipisicing elit. Neque obcaecati eveniet iste similique, placeat
              alias eligendi enim eius reprehenderit a officiis et voluptate qui
              saepe accusantium sit eum vitae deserunt.Lorem ipsum, dolor sit
              amet consectetur adipisicing elit. Impedit, aperiam corporis
              praesentium aut sed id in ab, nulla ad cum iure alias, numquam
              deleniti unde vero molestiae minus nemo illo?
            </p>
          </Container>
        </div>
      </div>
    </>
  );
};

export default (withTranslation(PrivacyPolicy));
