import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  fetchUserBillingAccountsListStart,
  setDefaultUserBillingAccountsStart,
  deleteUserBillingAccountsStart,
} from "../store/actions/WalletAction";

const SingleBillingAddressCard = (props) => {
  const { selectedAccount, setSelectedAccount, account } = props;
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();

  const setDefaultAccount = (e) => {
    props.dispatch(
      setDefaultUserBillingAccountsStart({
        user_billing_account_id: account.user_billing_account_id,
      })
    );
    e.stopPropagation();
  };

  const removeAccount = (e) => {
    props.dispatch(
      deleteUserBillingAccountsStart({
        user_billing_account_id: account.user_billing_account_id,
      })
    );
    e.stopPropagation();
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.defaultUserBillingAccounts.loading &&
      Object.keys(props.defaultUserBillingAccounts.data).length > 0
    ) {
      props.dispatch(fetchUserBillingAccountsListStart());
    }
    setSkipRender(false);
  }, [props.defaultUserBillingAccounts]);


  

  return (
    <div className="payment-radio-box">
      <div className="payment-radio-btn label-bg">
        <input
          name="plan"
          type="radio"
          className="radio"
          id="address-1"
          checked={selectedAccount === account.user_billing_account_id}
        />
        <label
          name="plan"
          className="radio"
          type="radio"
          checked={selectedAccount === account.user_billing_account_id}
          onClick={() => setSelectedAccount(account.user_billing_account_id)}
        >
          <div className="billing-details">
            <h5>
              {account.first_name} {account.last_name}
            </h5>
            <p>
              {new Array(account.account_number.length - 4 + 1).join("x") +
                account.account_number.slice(-4)}
            </p>
            {account.is_default == 1 ? (
              <span className="text-success">{t("default")}</span>
            ) : props.defaultUserBillingAccounts.loadingButtonContent &&
              props.defaultUserBillingAccounts.data.user_billing_account_id ===
                account.user_billing_account_id ? (
              props.defaultUserBillingAccounts.loadingButtonContent
            ) : (
              <span
                onClick={(event) => setDefaultAccount()}
                className="text-warning"
                style={{ cursor: "pointer" }}
              >
                {t("set_as_default")}
              </span>
            )}
          </div>
          {
            <span
              onClick={removeAccount}
              className="text-danger card-delete del"
            >
              {props.deleteUserBillingAccounts.loadingButtonContent &&
              props.deleteUserBillingAccounts.data.user_billing_account_id ===
                account.user_billing_account_id ? (
                props.deleteUserBillingAccounts.loadingButtonContent
              ) : (
                <img
                  src={window.location.origin + "/images/delete.svg"}
                  width="25"
                  height="25"
                />
              )}
            </span>
          }
        </label>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  userBillingAccounts: state.wallet.userBillingAccounts,
  defaultUserBillingAccounts: state.wallet.defaultUserBillingAccounts,
  deleteUserBillingAccounts: state.wallet.deleteUserBillingAccounts,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SingleBillingAddressCard));
