import {
  SAVE_CART_DETAILS_START,
  SAVE_CART_DETAILS_SUCCESS,
  SAVE_CART_DETAILS_FAILURE,
  FETCH_CART_LIST_START,
  FETCH_CART_LIST_SUCCESS,
  FETCH_CART_LIST_FAILURE,
  DELETE_CART_DETAILS_START,
  DELETE_CART_DETAILS_SUCCESS,
  DELETE_CART_DETAILS_FAILURE,
  GUEST_CART_LIST,
  FETCH_GUEST_CART_LIST_START,
  FETCH_GUEST_CART_LIST_SUCCESS,
  FETCH_GUEST_CART_LIST_FAILURE,
  UPDATE_GUEST_CART_LIST_START,
} from "../actions/ActionConstant";

const initialState = {
  cartSave: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false
  },
  cartList: {
    data: {},
    loading: true,
    error: false,
  },
  cartDelete: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false
  },
  guestCartList: [],
  guestCart: {
    data: {},
    loading: true,
    error: false,
  }
};

const cartsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CART_DETAILS_START:
      return {
        ...state,
        cartSave: {
          data: action.data,
          loading: true,
          error: false,
          loadingButtonContent: "...",
          buttonDisable: true
        },
      };
    case SAVE_CART_DETAILS_SUCCESS:
      return {
        ...state,
        cartSave: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false
        },
      };
    case SAVE_CART_DETAILS_FAILURE:
      return {
        ...state,
        cartSave: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false
        },
      };
    case FETCH_CART_LIST_START:
      return {
        ...state,
        cartList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_CART_LIST_SUCCESS:
      return {
        ...state,
        cartList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_CART_LIST_FAILURE:
      return {
        ...state,
        cartList: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case DELETE_CART_DETAILS_START:
      return {
        ...state,
        cartDelete: {
          data: action.data,
          loading: true,
          error: false,
          loadingButtonContent: "...",
          buttonDisable: true
        },
      };
    case DELETE_CART_DETAILS_SUCCESS:
      return {
        ...state,
        cartDelete: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false
        },
      };
    case DELETE_CART_DETAILS_FAILURE:
      return {
        ...state,
        cartDelete: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false
        },
      };
    case GUEST_CART_LIST:
      return {
        ...state,
        guestCartList: action.data,
      };
    case FETCH_GUEST_CART_LIST_START:
      return {
        ...state,
        guestCart: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading",
          buttonDisable: true,
        }
      };
    case UPDATE_GUEST_CART_LIST_START:
      return state;
    case FETCH_GUEST_CART_LIST_SUCCESS:
      return {
        ...state,
        guestCart: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      };
    case FETCH_GUEST_CART_LIST_FAILURE:
      return {
        ...state,
        guestCart: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      };
    default:
      return state;
  }
}


export default cartsReducer;