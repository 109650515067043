import React from "react";
import "./AgeVerification.css";
import * as Yup from "yup";
import { Form, Image, Button } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { getErrorNotificationMessage } from "../Helper/ToastNotification";
import { connect } from "react-redux";
import { useState } from "react";
import configuration from "react-global-configuration";

const maxYear = new Date().getFullYear();

const AgeVerificationIndex = (props) => {
  const validationSchema = Yup.object().shape({
    day: Yup.string().required("Required").min(1, "Minimum 2 digits!").max(2, "Invaid day"),
    month: Yup.string().required("Required").min(1, "Minimum 2 digits!"),
    year: Yup.string().required("Required").min(4, "Minimum 4 digits!"),
  });

  return (
    <>
      <div className="age-verification-sec">
        <div className="age-verification-header">
          <div className="age-verification-logo">
            <Image
              src={configuration.get("configData.site_logo")}
              type="image/png"
            />
          </div>
        </div>
        <div className="age-verification-welcome-sec">
          <h2>Welcome To {configuration.get("configData.site_name")}</h2>
          <p>
            {configuration.get("configData.site_name")} only online retailer and wholesaler of legal
            recreational cannabis.
          </p>
          <h4>We only sell to adults age 19 years or older.</h4>
          <p>
            Please enter your birthdate to confirm you are at least 19 years of
            age.
          </p>
        </div>
        <div className="age-verification-form">
          <div className="login-form">
            <Formik
              initialValues={{
                day: "",
                month: "",
                year: "",
              }}
              onSubmit={(values) => props.handleSubmit(values)}
              validationSchema={validationSchema}
            >
              {({ touched, errors, values, setFieldValue }) => (
                <FORM>
                  <h4>Date of Birth</h4>
                  <div className="age-verification-input mb-3 ">
                    <Form.Group>
                      <Field
                        type="number"
                        className="form-control"
                        name="day"
                        placeholder="DD"
                        min="1"
                        max="31"
                        onChange={e => {
                          if (e.target.value !== "" && (e.target.value.length > 2 || e.target.value > 31 || e.target.value < 1))
                            e.preventDefault();
                          else
                            setFieldValue("day", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="day"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Field
                        type="number"
                        className="form-control"
                        name="month"
                        placeholder="MM"
                        min="1"
                        max="12"
                        onChange={e => {
                          if (e.target.value !== "" && (e.target.value.length > 2 || e.target.value > 12 || e.target.value < 1))
                            e.preventDefault();
                          else
                            setFieldValue("month", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="month"
                        className="text-danger"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Field
                        type="number"
                        className="form-control"
                        name="year"
                        placeholder="YYYY"
                        onChange={e => {
                          if (e.target.value !== "" && (e.target.value.length > 4 || e.target.value > maxYear || e.target.value < 1))
                            e.preventDefault();
                          else
                            setFieldValue("year", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="year"
                        className="text-danger"
                      />
                    </Form.Group>
                  </div>
                  {!props.isUserEligible && (
                    <div className="age-verification-note">
                      You need be over age 19 to be able to access {configuration.get("configData.site_name")}.
                    </div>
                  )}
                  <Button
                    disabled={
                      !values.day ||
                      !values.month ||
                      !values.year ||
                      props.validateBtn.buttonDisable
                    }
                    type="submit"
                    className="subscribe-btn mt-3"
                  >
                    {props.validateBtn.content
                      ? props.validateBtn.content
                      : "Verify"}
                  </Button>
                </FORM>
              )}
            </Formik>
          </div>
        </div>
        <div className="age-verification-footer">
          <h3>Cannabis Made Clear</h3>
          <p>
            Get the facts about cannabis consumption, health effects and harm
            reduction.
          </p>
          {/* <div className="age-verification-footer-link">
            <ul>
              <li>
                <Link to="">Explore</Link>
              </li>
              <li>
                <Link to="">Why Buy Legal</Link>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapDispatchToProps)(
  withTranslation(AgeVerificationIndex)
);
