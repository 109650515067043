import React, { useEffect } from "react";
import "./TransactionHash.css";
import { Row, Col, Container, Form, Image, Button } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Link, Navigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { fetchOrdersDetailsStart } from "../store/actions/OrdersAction";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";

const SingleTransaction = (props) => {
  const t = useTranslation();
  const params = useParams();


  useEffect(() => {
    props.dispatch(fetchOrdersDetailsStart({ order_unique_id: params.orderUniqueId }));
  }, [params.orderUniqueId]);

  const copyToClipboard = (address, type) => {
    navigator.clipboard.writeText(address);
    getSuccessNotificationMessage(
      `${type} copied to clipboard`
    );
  };

  return (
    <>
      <div className="transaction-hash-sec">
        <Container>
          {props.orderDetails.loading ?
            <>
              <Skeleton count={2} height={100} className='mb-2' />
              <Skeleton count={2} height={200} className='mb-2' />
            </>
            : props.orderDetails.data.order ?
              (
                <Row>
                  <Col md={12}>
                    <div className="order-tracking-header">
                      <div className="order-tracking-header-info">
                        <Button className="back-btn">
                          <Image
                            src={window.location.origin + "/images/back-button.svg"}
                          />
                        </Button>
                        <h4>{t("tracking_order_number")} {props.orderDetails.data.order.order_unique_id}</h4>
                      </div>
                      <div className="order-tracking-action-btn">
                        <div className="order-tracking-status-card">
                          <span>Status</span>
                          {props.orderDetails.data.order.status_formatted}
                        </div>
                      </div>
                    </div>

                    <div className="transaction-order-details">
                      <div className="single-order-total">
                        <div className="transaction-code-sec">
                          <div className="transaction-address-copy">
                            <h4>{t("wallet_address")}</h4>
                            <div className="transaction-key">
                              {props.orderDetails.data.order.order_payment.wallet_address ? (
                                <>
                                  <h4>
                                    {props.orderDetails.data.order.order_payment.wallet_address.substr(0, 5)}
                                    ...
                                    {props.orderDetails.data.order.order_payment.wallet_address.substr(
                                      props.orderDetails.data.order.order_payment.wallet_address.length - 4
                                    )}
                                  </h4>
                                  <Button
                                    onClick={() => copyToClipboard(props.orderDetails.data.order.order_payment.wallet_address, "Wallet Address")}
                                  >
                                    <Image
                                      src={window.location.origin + "/images/copy.svg"}
                                    />
                                  </Button>
                                </>
                              ) : (
                                <h4>{t("not_applicable")}</h4>
                              )}
                            </div>
                          </div>

                          <div className="transaction-address-copy">
                            <h4>{t("transaction_hash")}</h4>
                            <div className="transaction-key">
                              {props.orderDetails.data.order.order_payment.transaction_hash ? (
                                <>
                                  <h4>
                                    {props.orderDetails.data.order.order_payment.transaction_hash.substr(0, 5)}
                                    ...
                                    {props.orderDetails.data.order.order_payment.transaction_hash.substr(
                                      props.orderDetails.data.order.order_payment.transaction_hash.length - 4
                                    )}
                                  </h4>
                                  <Button
                                    onClick={() => copyToClipboard(props.orderDetails.data.order.order_payment.transaction_hash, "Transaction Hash")}
                                  >
                                    <Image
                                      src={window.location.origin + "/images/copy.svg"}
                                    />
                                  </Button>
                                </>
                              ) : (
                                <h4>{t("not_applicable")}</h4>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="order-tracking-amount-sec">
                          <h3>{t("order_summary")}</h3>
                          <div className="cart-summary-info">
                            <div className="cart-summary-header">
                              <p>
                                {t("cart_total")}
                                <span>{props.orderDetails.data.order.order_amount.order_total_amount}</span>
                              </p>
                              <p>
                                {t("discount")}
                                <span>{props.orderDetails.data.order.order_amount.order_discount_total}</span>
                              </p>
                            </div>
                            <div className="cart-summary-total-amount">
                              <h5>
                                {t("order_total")}:
                                <span>{props.orderDetails.data.order.order_amount.order_checkout_amount}</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="single-order-details-sec mt-5">
                        {props.orderDetails.data.order.order_products.map((products, i) => (
                          <div className="single-order-sec">
                            <div className="cart-list-left-sec">
                            <Link
                                  to={`/product/${products.product.product_unique_id}`}
                                  className="no-link"
                                >
                              <div className="cart-list-img-sec">
                                <Image
                                  className="cart-list-img"
                                  src={products.product.file}
                                />
                              </div>
                              </Link>
                              <div className="cart-list-info">
                                <h4> <Link
                                  to={`/product/${products.product.product_unique_id}`}
                                  className="no-link transaction"
                                >
                                  {products.product.name} </Link></h4>
                                <p>
                                  {t("category")} <span>{products.product.category_name}</span>
                                </p>
                                <p>
                                  {t("vol")} <span>{products.product.gross_weight_formatted}</span>
                                </p>
                                <p>
                                  {t("qty")}
                                  <span>{products.quantity}</span>
                                </p>
                                <p className="text-danger"></p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              )
              : <NoDataFound />
          }
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  orderDetails: state.orders.orderDetails
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SingleTransaction));
