import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { saveOrdersCartSuccess } from "../store/actions/OrdersAction";

const OrderPlaced = (props) => {
  const navigate = useNavigate();

  const [orderNumber, setOrderNumber] = useState("");


  const t = useTranslation();

  // useEffect(() => {
  //   if (Object.keys(props.ordersCheckout.data).length > 0) {
  //     setOrderNumber(props.ordersCheckout.data.order_unique_id);
  //   } else {
  //     navigate("/");
  //   }
  // }, []);

  useEffect(() => {
    if (Object.keys(props.orderSaveCart.data).length > 0) {
      setOrderNumber(props.orderSaveCart.data.order_unique_id);
    } else {
      navigate("/");
    }
  }, []);

  return (
    <>
      {orderNumber
        ? <div className="order-placed-sec">
          <div className="order-placed-status">
            <div className="order-placed-img">
              <Image
                src={window.location.origin + "/images/order-placed.svg"}
                type="image/png"
              />
            </div>
            <div className="order-placed-content text-center">
              <h3>{t("order_placed_text")}</h3>
              <p>{t("order_processed_text")}</p>
              <h5>{t("order_number")} {orderNumber}</h5>
            </div>
            <div className="order-placed-btn">
              <Link to="/shop" className="continue-shopping-btn">
                {t("continue_shopping")}
              </Link>
            </div>
          </div>
        </div>
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  ordersCheckout: state.orders.ordersCheckout,
  orderSaveCart: state.orders.orderSaveCart,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(OrderPlaced));
