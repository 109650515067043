import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Dropdown,
  Table
} from "react-bootstrap";
import ProfileSideBar from "./ProfileSideBar";
import { Link } from "react-router-dom";
import {
  transactionListStart,
  moreTransactionListStart,
} from "../store/actions/OrdersAction";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getSuccessNotificationMessage } from "../Helper/ToastNotification";
import { connect } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import NoDataFound from "../Helper/NoDataFound";
import OrderDetailsNotFound from "../Helper/OrderDetailsNotFound";

const OrderTransactions = (props) => {
  const t = useTranslation();
  useEffect(() => {
    props.dispatch(
      transactionListStart()
    );
  }, []);
  const fetchMoreTransactions = () => {
    props.dispatch(
      moreTransactionListStart({
        skip: props.transactionLists.data.order_payments.length,
        take: 12,
      })
    );
  };
  const onCopy = () => {
    props.dispatch(getSuccessNotificationMessage(t("transaction_id_copied")));
  };
  return (
    <div className="profile-sec">
      <Container>
        <Row>
          <Col xl={3} md={12} lg={4}>
            <div className="new-mobile-section">
              <ProfileSideBar />
            </div>
          </Col>
          <Col xl={9} md={12} lg={8}>
            <div className="profile-details-sec">
              <div className="profile-details-title">
                <h4>{t("order_transactions")}</h4>
              </div>
              <div className="wallet-table-sec mt-4">
                <h5>{t("all_transactions")}</h5>
                {props.transactionLists.loading ? (
                  <CommonCenterLoader />
                ) : (
                  <InfiniteScroll
                    dataLength={
                      props.transactionLists.data.order_payments.length
                    }
                    next={fetchMoreTransactions}
                    hasMore={
                      props.transactionLists.data.order_payments.length <
                      props.transactionLists.data.total_order_payments

                    }
                    loader={<CommonCenterLoader />}
                  >
                    {props.transactionLists.data.order_payments &&
                      props.transactionLists.data.order_payments.length >
                      0 ? (
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>{t("date")}</th>
                            <th>{t("transaction_id")}</th>
                            <th>{t("amount")}</th>
                            <th>{t("status")}</th>
                            <th>{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.transactionLists.data.order_payments.map(
                            (orders, i) => (
                              <tr key={i}>
                                <td>{orders.paid_date}</td>
                                <td style={{ cursor: "pointer" }}>
                                  {orders.payment_id ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip>{orders.payment_id}</Tooltip>
                                      }
                                    >
                                      <div className="transaction-id-sec">
                                        <CopyToClipboard
                                          text={orders.payment_id}
                                          onCopy={() => onCopy()}
                                        >
                                          <span>{orders.payment_id}</span>
                                        </CopyToClipboard>
                                      </div>
                                    </OverlayTrigger>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>
                                  {orders.total_formatted}
                                </td>
                                <td className="text-success">
                                  {orders.status_formatted}
                                </td>
                                <td>
                                  <Link className="black-btn" to={`/order-transactions/${orders.order_unique_id}`}>
                                    {t("view")}
                                  </Link>
                                </td>
                              </tr>)
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <>
                        <NoDataFound />
                        <div className="continue-shopping-btn-sec text-center">
                          <Link to="/shop" className="continue-shopping-btn">
                            {t("continue_shopping")}
                          </Link>
                        </div>
                      </>
                    )}
                  </InfiniteScroll>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToPros = (state) => ({
  transactionLists: state.orders.transactionLists,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(OrderTransactions));
