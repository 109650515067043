import {
  FETCH_BANNERS_LIST_START,
  FETCH_BANNERS_LIST_SUCCESS,
  FETCH_BANNERS_LIST_FAILURE,
  FETCH_CATEGORIES_LIST_START,
  FETCH_CATEGORIES_LIST_SUCCESS,
  FETCH_CATEGORIES_LIST_FAILURE,
  FETCH_TOP_PRODUCTS_LIST_START,
  FETCH_TOP_PRODUCTS_LIST_SUCCESS,
  FETCH_TOP_PRODUCTS_LIST_FAILURE,
  FETCH_FAQS_LIST_START,
  FETCH_FAQS_LIST_SUCCESS,
  FETCH_FAQS_LIST_FAILURE,
  CONTACT_SUBMIT_START,
  CONTACT_SUBMIT_SUCCESS,
  CONTACT_SUBMIT_FAILURE,
  CUSTOMER_REVIEWS_LIST_START,
  CUSTOMER_REVIEWS_LIST_SUCCESS,
  CUSTOMER_REVIEWS_LIST_FAILURE
} from "./ActionConstant";

export function fetchBannersListStart(data) {
  return {
    type: FETCH_BANNERS_LIST_START,
    data,
  };
}

export function fetchBannersListSuccess(data) {
  return {
    type: FETCH_BANNERS_LIST_SUCCESS,
    data,
  }
}

export function fetchBannersListFailure(error) {
  return {
    type: FETCH_BANNERS_LIST_FAILURE,
    error,
  }
}

export function fetchCategoriesListStart(data) {
  return {
    type: FETCH_CATEGORIES_LIST_START,
    data,
  };
}

export function fetchCategoriesListSuccess(data) {
  return {
    type: FETCH_CATEGORIES_LIST_SUCCESS,
    data,
  };
}

export function fetchCategoriesListFailure(error) {
  return {
    type: FETCH_CATEGORIES_LIST_FAILURE,
    error,
  };
}

export function fetchTopProductsListStart(data) {
  return {
    type: FETCH_TOP_PRODUCTS_LIST_START,
    data,
  };
}

export function fetchTopProductsListSuccess(data) {
  return {
    type: FETCH_TOP_PRODUCTS_LIST_SUCCESS,
    data,
  };
}

export function fetchTopProductsListFailure(error) {
  return {
    type: FETCH_TOP_PRODUCTS_LIST_FAILURE,
    error,
  };
}

export function fetchFaqsListStart(data) {
  return {
    type: FETCH_FAQS_LIST_START,
    data,
  };
}

export function fetchFaqsListSuccess(data) {
  return {
    type: FETCH_FAQS_LIST_SUCCESS,
    data,
  };
}

export function fetchFaqsListFailure(error) {
  return {
    type: FETCH_FAQS_LIST_FAILURE,
    error,
  };
}

export function contactSubmitStart(data) {
  return {
    type: CONTACT_SUBMIT_START,
    data,
  };
}

export function contactSubmitSuccess(data) {
  return {
    type: CONTACT_SUBMIT_SUCCESS,
    data,
  };
}

export function contactSubmitFailure(error) {
  return {
    type: CONTACT_SUBMIT_FAILURE,
    error,
  };
}

export function customerReviewsListStart(data) {
  return {
    type: CUSTOMER_REVIEWS_LIST_START,
    data,
  };
}

export function customerReviewsListSuccess(data) {
  return {
    type: CUSTOMER_REVIEWS_LIST_SUCCESS,
    data,
  };
}

export function customerReviewsListFailure(error) {
  return {
    type: CUSTOMER_REVIEWS_LIST_FAILURE,
    error,
  };
}