import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";

import {
  FETCH_BANNERS_LIST_START,
  FETCH_CATEGORIES_LIST_START,
  FETCH_TOP_PRODUCTS_LIST_START,
  FETCH_FAQS_LIST_START,
  CONTACT_SUBMIT_START,
  CUSTOMER_REVIEWS_LIST_START
} from "../actions/ActionConstant";

import {
  fetchBannersListSuccess,
  fetchBannersListFailure,
  fetchCategoriesListSuccess,
  fetchCategoriesListFailure,
  fetchTopProductsListSuccess,
  fetchTopProductsListFailure,
  fetchFaqsListSuccess,
  fetchFaqsListFailure,
  contactSubmitSuccess,
  contactSubmitFailure,
  customerReviewsListSuccess,
  customerReviewsListFailure
} from "../actions/HomePageAction";

function* fetchBannersListAPI() {
  try {
    const response = yield api.postMethod("banners_list");

    if (response.data.success) {
      yield put(fetchBannersListSuccess(response.data.data));
    } else {
      yield put(fetchBannersListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchBannersListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchCategoriesListAPI() {
  try {
    const response = yield api.postMethod("categories_list");

    if (response.data.success) {
      yield put(fetchCategoriesListSuccess(response.data.data));
    } else {
      yield put(fetchCategoriesListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchCategoriesListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


function* fetchTopProductsListAPI() {
  try {
    const response = yield api.postMethod("top_products_list");

    if (response.data.success) {
      yield put(fetchTopProductsListSuccess(response.data.data));
    } else {
      yield put(fetchTopProductsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchTopProductsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


function* fetchFaqsListAPI() {
  try {
    const response = yield api.postMethod("faqs_list");

    if (response.data.success) {
      yield put(fetchFaqsListSuccess(response.data.data));
    } else {
      yield put(fetchFaqsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchFaqsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


function* contactRequestsSaveAPI(action) {
  try {
    const response = yield api.postMethod("contact_requests_save", action.data);
    if (response.data.success) {
      yield put(contactSubmitSuccess(response.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(contactSubmitFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(contactSubmitFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchCustomerReviewsListAPI(action) {
  try {
    const response = yield api.postMethod("customer_reviews_list", action.data);

    if (response.data.success) {
      yield put(customerReviewsListSuccess(response.data.data));
    } else {
      yield put(customerReviewsListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(customerReviewsListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}



export default function* pageSaga() {
  yield all([
    yield takeLatest(FETCH_BANNERS_LIST_START, fetchBannersListAPI),
    yield takeLatest(FETCH_CATEGORIES_LIST_START, fetchCategoriesListAPI),
    yield takeLatest(FETCH_TOP_PRODUCTS_LIST_START, fetchTopProductsListAPI),
    yield takeLatest(FETCH_FAQS_LIST_START, fetchFaqsListAPI),
    yield takeLatest(CONTACT_SUBMIT_START, contactRequestsSaveAPI),
    yield takeLatest(CUSTOMER_REVIEWS_LIST_START, fetchCustomerReviewsListAPI)
  ]);
}


