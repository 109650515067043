import React, { useEffect } from "react";
import api from "../Environment";
import { useNavigate } from "react-router-dom";
import { fetchUserDetailsFailure } from "../store/actions/UserAction";
import { connect } from "react-redux";

const LogoutIndex = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    // api.postMethod("logout").then((response) => {
    //   if (response.data.success) {
    //     console.log("success");
    //   } else {
    //   }
    // });
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userLoginStatus");
    localStorage.removeItem("user_picture");
    localStorage.removeItem("user_cover");
    localStorage.removeItem("name");
    localStorage.removeItem("username");
    localStorage.removeItem("user_unique_id");
    localStorage.removeItem("email");
    props.dispatch(fetchUserDetailsFailure());
    navigate("/");
  }, []);
  return "";
};


function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(LogoutIndex);
