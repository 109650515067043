import React, { useState } from "react";
import EmptyFooter from "./Footer/EmptyFooter";
import EmptyHeader from "./Header/EmptyHeader";
import {
  Navbar,
  Container,
  Image,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import configuration from "react-global-configuration";
import { withTranslation, useTranslation } from "react-multi-lang";

const CheckoutLayout = (props) => {

	const navigate = useNavigate();
	const t = useTranslation();

	const redirectHome = () => {
		if (window.confirm(t('exit_checkout_confirmation'))) {
			console.log("truess")
			window.location.href = "/";
		}
	}

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Link to="#" className="navbar-brand" onClick={redirectHome}>
              <Image
                className="lazyload logo"
                src={configuration.get("configData.site_logo")}
                type="image/png"
              />
            </Link>
          </Container>
        </Navbar>
      </header>
      {props.children}
      <EmptyFooter />
    </>
  );
};

export default CheckoutLayout;
